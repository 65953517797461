import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import translation from '@onpace/onspace-core/components/translations'

/// The Onspace Player Advertisement is an element to display advertisement metadata.
///
/// This class can be used directly or overridden as required.
export default class OnspacePlayerAdvertisement extends CustomHTMLElement {
  /// Runs when the advertisement element is first connected to the DOM.
  ///
  /// The following parameters are supported:
  /// [canSkip]
  ///   Indicates if the advertisement can be skipped. When +true+, this will add a "skip" button during playback. You
  ///   can also specify an integer number, which specifies that the ad can be skipped after that many seconds.
  /// [metadata]
  ///   An optional object describing the advertisement. When present, this will cause an overlay to be shown with
  ///   this data. This supports the same attributes as the top-level +metadata+ attribute.
  /// [clickCallback]
  ///   An optional callback which will be run when the metadata element is clicked. This will also pause playback.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.player = options.player

    this.classList.add('onspace-player__advertisement')

    this.setupMetadataElement(options.metadata, options.clickCallback)
    this.setupContinueElement(options.canSkip)
  }

  ////////// Metadata

  /// Creates the metadata element.
  ///
  /// The metadata element contains information about the advertisement, given by the +metadata+ initialisation option.
  setupMetadataElement(metadata, clickCallback) {
    const metadataElement = document.createElement('div')
    metadataElement.classList.add('onspace-player__advertisement__metadata')

    metadata = metadata || {}

    if (metadata.artwork) {
      const artworkElement = document.createElement('img')
      artworkElement.src = metadata.artwork
      metadataElement.appendChild(artworkElement)
    }

    const contentElement = document.createElement('div')

    const sponsoredElement = document.createElement('span')
    sponsoredElement.classList.add('onspace-player__advertisement__metadata__sponsored')
    sponsoredElement.innerText = translation('onspace.media.player.advertisement.sponsored')
    contentElement.appendChild(sponsoredElement)

    if (metadata.title) {
      const titleElement = document.createElement('span')
      titleElement.classList.add('onspace-player__advertisement__metadata__title')
      titleElement.innerText = metadata.title
      contentElement.appendChild(titleElement)
    }

    if (metadata.subtitle) {
      const subtitleElement = document.createElement('span')
      subtitleElement.classList.add('onspace-player__advertisement__metadata__subtitle')
      subtitleElement.innerText = metadata.subtitle
      contentElement.appendChild(subtitleElement)
    }

    metadataElement.appendChild(contentElement)

    if (typeof clickCallback === 'function') {
      metadataElement.classList.add('onspace-player__advertisement__metadata--clickable')

      metadataElement.addEventListener('mousedown', (event) => {
        event.stopPropagation()
      })
      metadataElement.addEventListener('touchstart', (event) => {
        event.stopPropagation()
      })
      metadataElement.addEventListener('click', (event) => {
        this.player.pausePlayback()

        event.preventDefault()
        event.stopPropagation()
        clickCallback()
      })

      const clickoutElement = SVGElement.createOnspaceSpritemapSvg('onspace/player_clickout')
      metadataElement.appendChild(clickoutElement)
    }

    this.appendChild(metadataElement)
  }

  ////////// Continue

  /// Creates the continue element.
  ///
  /// This informs the user the video will continue after the ad, and allows them to skip if configured. If available,
  /// this will show the player's artwork.
  setupContinueElement(canSkip) {
    this.continueElement = document.createElement('div')
    this.continueElement.classList.add('onspace-player__advertisement__continue')

    this.continueTextElement = document.createElement('span')
    this.continueTextElement.classList.add('onspace-player__advertisement__continue__text')
    this.continueElement.appendChild(this.continueTextElement)

    if (typeof canSkip === 'number') {
      this.playerPositionHandler = this.playerPositionChanged.bind(this)
      this.player.addEventListener('onspace:media:player:position-changed', this.playerPositionHandler)

      this.skipDuration = canSkip

      this.playerPositionChanged()
    } else if (canSkip) {
      this.continueElement.addEventListener('click', this.continueClicked.bind(this))
      this.continueElement.classList.add('onspace-player__advertisement__continue--skippable')
      this.continueTextElement.innerText = translation('onspace.media.player.advertisement.continue_skip')
    } else {
      this.continueTextElement.innerText = translation('onspace.media.player.advertisement.continue_after')
    }

    const metadata = this.player.metadata || {}
    if (metadata.artwork) {
      const artworkElement = document.createElement('img')
      artworkElement.src = metadata.artwork
      this.continueElement.appendChild(artworkElement)
    }

    this.appendChild(this.continueElement)
  }

  /// Responds to the playback position of the player changing.
  ///
  /// This callback will only be used whilst the continue element is waiting to be skippable.
  playerPositionChanged() {
    if (this.player.position >= this.skipDuration) {
      this.player.removeEventListener('onspace:media:player:position-changed', this.playerPositionHandler)
      this.playerPositionHandler = null

      this.continueElement.addEventListener('click', this.continueClicked.bind(this))
      this.continueElement.classList.add('onspace-player__advertisement__continue--skippable')
      this.continueTextElement.innerText = translation('onspace.media.player.advertisement.continue_skip')
    } else {
      const remaining = this.skipDuration - this.player.position

      this.continueTextElement.innerText = `${translation('onspace.media.player.advertisement.continue_skip_in')} ${Math.round(remaining)}`
    }
  }

  /// Responds to clicks on the continue element.
  ///
  /// Once the skip countdown has passed (if applicable), this will inform the player to complete the advertisement.
  continueClicked() {
    this.player.advertisementCompleted()
  }
}

window.customElements.define('onspace-player-advertisement', OnspacePlayerAdvertisement)
