import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import OnspacePlayerSelector from '@onpace/onspace-media/elements/player/selector'
import OnspacePlayerShowcase from '@onpace/onspace-media/elements/player/showcase'

/// The Onspace Player Tabs element creates expandable UI to display additional content and information.
export default class OnspacePlayerTabs extends CustomHTMLElement {
  runConstructor(options = {}) {
    super.runConstructor(options)

    this._selectedTabIndex = null
  }

  /// Runs when the element is first connected to the DOM.
  ///
  /// The following parameters are supported:
  /// [player]
  ///   The player which will display the tabs.
  runFirstConnected(options = {}) {
    super.runFirstConnected()

    this.player = options.player

    this.classList.add('onspace-player-tabs')

    this.buttonsElement = document.createElement('div')
    this.buttonsElement.classList.add('onspace-player-tabs__buttons')
    this.appendChild(this.buttonsElement)
    this.updateButtons()

    this.contentElement = document.createElement('div')
    this.contentElement.classList.add('onspace-player-tabs__content')
    this.appendChild(this.contentElement)
  }

  ////////// Tabs

  /// Retrieves the data for the current tabs.
  get tabData() {
    return this._tabData
  }

  /// Updates the current tab data.
  set tabData(newval) {
    this._tabData = newval || []

    this.updateButtons()
    this.selectedTabIndex = null
  }

  /// Retrieves the index of the currently selected tab.
  ///
  /// This will be +null+ if no tab is selected.
  get selectedTabIndex() {
    return this._selectedTabIndex
  }

  /// Sets the tab with the given index to be selected.
  set selectedTabIndex(newval) {
    if (newval === this._selectedTabIndex) { return }

    this._selectedTabIndex = newval

    if (typeof newval === 'number') {
      const tab = this.tabData[newval]
      this.showTabContent(tab, newval)
    } else {
      this.collapseContent()
    }
  }

  /// Expands or collapses the given tab index.
  toggleTabIndex(index) {
    if (this.selectedTabIndex === index) {
      this.selectedTabIndex = null
    } else {
      this.selectedTabIndex = index
    }
  }

  /// Indicates if the tabs are currently expanded.
  get expanded() {
    return typeof this.selectedTabIndex === 'number'
  }

  ////////// Buttons

  /// Updates the tab buttons from the current data.
  ///
  /// This will deselect a tab if one is selected.
  updateButtons() {
    if (!this.buttonsElement || !this.tabData) { return }

    this.buttonsElement.innerHTML = ''

    this.tabData.forEach((tab, index) => {
      if (tab === null) { return }

      const button = document.createElement('a')
      button.classList.add('onspace-player-tabs__button')
      button.addPressEventListeners({ onPress: (_event) => this.toggleTabIndex(index) })

      const textElement = document.createElement('span')
      textElement.innerText = tab.title
      button.appendChild(textElement)

      if (!tab.content) {
        tab.content = this.createTabContent(tab)
      }

      this.buttonsElement.appendChild(button)
    })
  }

  ////////// Content

  /// Expands the content for the given tab.
  showTabContent(tab, index) {
    if (!this.buttonsElement) { return }

    const highlightedButton = this.buttonsElement.querySelector('[highlighted]')
    if (highlightedButton) { highlightedButton.removeAttribute('highlighted') }

    const button = this.buttonsElement.children[index]
    button.setAttribute('highlighted', '')

    if (!tab.content) {
      tab.content = this.createTabContent(tab)
    }
    this.updateTabContent(tab)

    this.contentElement.innerHTML = ''
    this.contentElement.appendChild(tab.content)

    this.contentElement.style.height = `${tab.content.getBoundingClientRect().height}px`

    this.classList.add('onspace-player-tabs__content--expanded')
    this.triggerEvent('onspace:media:tabs:expanded')
  }

  /// Collapses the content.
  collapseContent() {
    if (!this.buttonsElement) { return }

    const highlightedButton = this.buttonsElement.querySelector('[highlighted]')
    if (highlightedButton) { highlightedButton.removeAttribute('highlighted') }

    const onTransitionEnd = () => {
      this.contentElement.removeEventListener('transitionend', onTransitionEnd)
      this.contentElement.innerHTML = ''
    }
    this.contentElement.addEventListener('transitionend', onTransitionEnd)
    this.contentElement.style.height = ''
    this.classList.remove('onspace-player-tabs__content--expanded')

    this.triggerEvent('onspace:media:tabs:collapsed')
  }

  /// Creates the element required for the given tab.
  createTabContent(tab) {
    switch (tab.type) {
    case 'showcase': {
      const showcase = new OnspacePlayerShowcase()
      showcase.setupElement({ metadata: tab.metadata, actions: tab.actions })
      return showcase
    }
    case 'selector': {
      const selector = new OnspacePlayerSelector()
      tab.items.forEach((item) => selector.addItem(item))
      selector.addEventListener('onspace:media:player-selector:item-clicked', (event) => {
        const item = tab.items.find((item) => item.id == event.detail)
        if (item) {
          this.player.replaceMedia({ metadata: item })
        }
      })
      return selector
    }
    case 'custom': {
      const element = tab.create()
      element.classList.add('onspace-player-tabs__content__custom')
      return element
    }
    }
  }

  /// Updates the element for the given tab.
  updateTabContent(tab) {
    switch (tab.type) {
    case 'selector':
      if (this.player.metadata && typeof this.player.metadata.id === 'string') {
        tab.content.itemElements.forEach((element) => {
          if (element.itemId === this.player.metadata.id) {
            element.setIndicatorIcon('onspace/icon_checkmark')
          } else {
            element.setIndicatorIcon(null)
          }
        })
      } else {
        tab.content.itemElements.forEach((element) => element.setIndicatorIcon(null))
      }
      break
    case 'custom':
      if (typeof tab.update === 'function') {
        tab.update(tab.content)
      }
      break
    }
  }
}

window.customElements.define('onspace-player-tabs', OnspacePlayerTabs)
