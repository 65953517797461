import '@onpace/onspace-media/components/locale'
import '@onpace/onspace-media/components/video'

import '@onpace/onspace-core-react'
import playerDialog from '@onpace/onspace-media/elements/player/dialog'

import '@onpace/onspace-media-react/stylesheets/application'

import './player/wrapper'

import '@onpace/onspace-core-react/svgs/icon_checkmark'
import '@onpace/onspace-core-react/svgs/icon_cross'
import '@onpace/onspace-core-react/svgs/icon_loading'
import '@onpace/onspace-core-react/svgs/icon_plus'
import '@onpace/onspace-core-react/svgs/player_airplay'
import '@onpace/onspace-core-react/svgs/player_airplay_active'
import '@onpace/onspace-core-react/svgs/player_clickout'
import '@onpace/onspace-core-react/svgs/player_fast_backward'
import '@onpace/onspace-core-react/svgs/player_fast_forward'
import '@onpace/onspace-core-react/svgs/player_fullscreen_enter'
import '@onpace/onspace-core-react/svgs/player_fullscreen_exit'
import '@onpace/onspace-core-react/svgs/player_google_cast'
import '@onpace/onspace-core-react/svgs/player_google_cast_active'
import '@onpace/onspace-core-react/svgs/player_multiscreen'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_asymmetric2'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_asymmetric3'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_asymmetric4'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_grid2'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_grid3'
import '@onpace/onspace-core-react/svgs/player_multiscreen_landscape_grid4'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_asymmetric2'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_asymmetric3'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_asymmetric4'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_grid2'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_grid3'
import '@onpace/onspace-core-react/svgs/player_multiscreen_portrait_grid4'
import '@onpace/onspace-core-react/svgs/player_pause'
import '@onpace/onspace-core-react/svgs/player_pip'
import '@onpace/onspace-core-react/svgs/player_pip_enter'
import '@onpace/onspace-core-react/svgs/player_pip_exit'
import '@onpace/onspace-core-react/svgs/player_play'
import '@onpace/onspace-core-react/svgs/player_skip_backward'
import '@onpace/onspace-core-react/svgs/player_skip_forward'
import '@onpace/onspace-core-react/svgs/player_volume_loud'
import '@onpace/onspace-core-react/svgs/player_volume_low'
import '@onpace/onspace-core-react/svgs/player_volume_muted'
import '@onpace/onspace-core-react/svgs/player_volume_normal'

///// Configure Dialog

playerDialog.pageDidLoad()
