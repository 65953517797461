import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// The Onspace Player Button Bar is an element which contains various buttons and other similar elements.
export default class OnspacePlayerBar extends CustomHTMLElement {
  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    this.classList.add('onspace-player-bar')
  }

  ////////// Buttons

  /// Creates a button and adds it to the bar.
  ///
  /// This requires a unique +name+ to reference it by, and supports the following options:
  /// [icon]
  ///   The name of the icon to give the button.
  /// [pressed]
  ///   A function which will be called when the button is pressed.
  /// [pressBegan]
  ///   A function which will be called when the button's press starts.
  /// [pressEnded]
  ///   A function which will be called when the button's press finishes.
  ///
  /// The newly create button element will be returned. It includes a +setIcon+ function which will update the icon
  /// displayed on it.
  addButton(name, options={}) {
    const buttonElement = document.createElement('a')
    buttonElement.classList.add('onspace-player-bar__button')
    buttonElement.classList.add(`onspace-player-bar__button--${name}`)

    const svgElement = SVGElement.createOnspaceSpritemapSvg(options.icon)
    buttonElement.appendChild(svgElement)

    buttonElement.setIcon = (name) => svgElement.setOnspaceSpritemapSvg(name)

    buttonElement.setHighlighted = (highlighted) => {
      buttonElement.toggleAttribute('highlighted', highlighted)
    }

    if (options.pressBegan && options.pressEnded) {
      const pressBegan = (event) => {
        options.pressBegan(event)
        return this.barButtonPressBegan(event)
      }
      const pressEnded = (event) => {
        options.pressEnded(event)
        return this.barButtonPressEnded(event)
      }

      buttonElement.addEventListener('mousedown', pressBegan)
      buttonElement.addEventListener('mouseup', pressEnded)
      buttonElement.addEventListener('touchstart', pressBegan)
      buttonElement.addEventListener('touchend', pressEnded)
    } else if (options.pressed) {
      const pressBegan = this.barButtonPressBegan.bind(this)
      const pressEnded = (event) => {
        options.pressed(event)
        return this.barButtonPressEnded(event)
      }

      buttonElement.addEventListener('mousedown', pressBegan)
      buttonElement.addEventListener('mouseup', pressEnded)
      buttonElement.addEventListener('touchstart', pressBegan)
      buttonElement.addEventListener('touchend', pressEnded)
    } else {
      const preventEvent = (event) => {
        event.preventDefault()
        event.stopPropagation()
        return false
      }

      buttonElement.addEventListener('mousedown', preventEvent)
      buttonElement.addEventListener('mouseup', preventEvent)
      buttonElement.addEventListener('touchstart', preventEvent)
      buttonElement.addEventListener('touchend', preventEvent)
    }

    this.appendChild(buttonElement)

    return buttonElement
  }

  /// Creates a dropdown and adds it to a bar.
  ///
  /// This requires a unique +name+ to reference it by, and the +menuElement+ which is dynamically shown. Additionally,
  /// it supports the following options:
  /// [icon]
  ///   The name of the icon to give the button.
  /// [pressed]
  ///   A function which will be called when the button is pressed.
  ///
  ///   Note that this will override the default press functionality, which is to show the menu. The menu will only be
  ///   available via hover, which is not supported on touch devices.
  ///
  /// The newly created dropdown element will be returned. It includes a +setIcon+ function which will update the icon
  /// displayed on it.
  addDropdown(name, menuElement, options={}) {
    const dropdown = document.createElement('div')
    dropdown.classList.add('onspace-player-bar__dropdown')
    dropdown.classList.add(`onspace-player-bar__dropdown--${name}`)

    const buttonElement = this.addButton(name, { icon: options.icon, pressed: options.pressed })
    dropdown.appendChild(buttonElement)

    menuElement.classList.add('onspace-player-bar__dropdown__menu')
    dropdown.appendChild(menuElement)

    dropdown.setIcon = (name) => buttonElement.setIcon(name)

    Object.defineProperty(dropdown, 'active', {
      get: function() {
        return this._active
      },
      set: function(value) {
        this._active = value

        if (value) {
          dropdown.classList.add('onspace-player-bar__dropdown--active')
        } else {
          dropdown.classList.remove('onspace-player-bar__dropdown--active')
        }
      }
    })

    this.appendChild(dropdown)

    return dropdown
  }

  /// Creates a dropdown with a vertical slider as its menu and adds it to the bar.
  ///
  /// This requires a unique +name+ to reference it by, and supports the following options:
  /// [icon]
  ///   The name of the icon to given the button.
  /// [pressed]
  ///   A function which will be called when the button is pressed.
  /// [changed]
  ///   A function which will be called when the value is changed.
  ///
  /// The newly create button element will be returned. It includes a +setIcon+ function which will update the icon
  /// displayed on it. It also contains a +value+ getter and setter, which represents the value of the slider as a
  /// number from 0 to 1.
  addDropdownSlider(name, options={}) {
    const menuElement = document.createElement('div')

    const sliderElement = document.createElement('div')
    sliderElement.classList.add('onspace-player-bar__dropdown__slider')
    menuElement.appendChild(sliderElement)

    const trackElement = document.createElement('div')
    trackElement.classList.add('onspace-player-bar__dropdown__slider__track')
    sliderElement.appendChild(trackElement)

    const dropdown = this.addDropdown(name, menuElement, { icon: options.icon, pressed: options.pressed })

    dropdown.classList.add('onspace-player-bar__dropdown--slider')

    const pressBegan = (event) => {
      if (options.slideBegan) { options.slideBegan() }
      document.body.style.setProperty('cursor', 'grabbing', 'important')
      dropdown.active = true
      sliderElement.classList.add('onspace-player-bar__dropdown__slider--sliding')

      this.addDocumentBoundEventListener('mousemove', pressMoved)
      this.addDocumentBoundEventListener('touchmove', pressMoved)
      this.addDocumentBoundEventListener('mouseup', pressEnded)

      pressMoved(event)

      event.preventDefault()
      event.stopPropagation()
      return false
    }
    const pressMoved = (event) => {
      let clientY
      if (event.type.startsWith('mouse')) {
        clientY = event.clientY
      } else {
        clientY = event.touches[0].clientY
      }

      const boundingRect = sliderElement.getBoundingClientRect()

      let percentage = 1 - ((clientY - boundingRect.top) / boundingRect.height)
      percentage = Math.min(Math.max(percentage, 0), 1)

      dropdown.value = percentage
      options.changed(percentage)
    }
    const pressEnded = (event) => {
      if (options.slideEnded) { options.slideEnded() }
      document.body.style.cursor = ''
      dropdown.active = false
      sliderElement.classList.remove('onspace-player-bar__dropdown__slider--sliding')

      this.removeDocumentBoundEventListener('mousemove')
      this.removeDocumentBoundEventListener('touchmove')
      this.removeDocumentBoundEventListener('mouseup')

      event.preventDefault()
      event.stopPropagation()
      return false
    }

    sliderElement.addEventListener('mousedown', pressBegan)
    sliderElement.addEventListener('touchstart', pressBegan)
    sliderElement.addEventListener('touchend', pressBegan)

    Object.defineProperty(dropdown, 'value', {
      get: function() {
        return this._value
      },
      set: function(value) {
        this._value = value

        trackElement.style.height = `${value * 100}%`
      }
    })

    dropdown.value = 0

    return dropdown
  }

  ///// Events

  /// Responds to presses beginning on bar buttons.
  barButtonPressBegan(event) {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  /// Responds to presses ending on bar buttons.
  barButtonPressEnded(event) {
    event.preventDefault()
    event.stopPropagation()
    return false
  }

  ////////// Indicator

  /// Creates a non-interactive indicator and adds it to the bar.
  ///
  /// This requires a unique +name+ to reference it by, and supports the following options:
  /// [icon]
  ///   The name of the icon to give the indicator.
  /// [text]
  ///   Text to include in the indicator.
  ///
  /// The newly created indicator element will be returned. It includes +setIcon+ and +setText functions which update
  /// the icon and text displayed on it respectively.
  addIndicator(name, options={}) {
    const indicatorElement = document.createElement('a')
    indicatorElement.classList.add('onspace-player-bar__indicator')
    indicatorElement.classList.add(`onspace-player-bar__indicator--${name}`)

    const svgElement = SVGElement.createOnspaceSpritemapSvg(options.icon)
    indicatorElement.appendChild(svgElement)
    indicatorElement.setIcon = (name) => svgElement.setOnspaceSpritemapSvg(name)

    const textElement = document.createElement('div')
    textElement.classList.add('onspace-player-bar__indicator__text')
    textElement.innerText = options.text || ''
    indicatorElement.appendChild(textElement)
    indicatorElement.setText = (text) => textElement.innerText = text

    this.appendChild(indicatorElement)

    return indicatorElement
  }
}

window.customElements.define('onspace-player-bar', OnspacePlayerBar)
