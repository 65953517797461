import OnspacePlayerDebug from '@onpace/onspace-media/elements/player/debug'

/// The Onspace Player Debug element displays debugging information and controls for an video player.
export default class OnspaceVideoPlayerDebug extends OnspacePlayerDebug {
  /// Runs when the controls element is first connected to the DOM.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.prepareVideoItems()
  }

  ////////// Player

  /// Prepares the items for the "Player" section.
  ///
  /// This overrides OnspaceMediaPlayer.preparePlayerItems to add items specific to video media.
  preparePlayerItems() {
    super.preparePlayerItems()

    this.addItem('Resolution', (element) => {
      const callback = () => element.innerText = `${this.player.nativePlayer.offsetWidth}×${this.player.nativePlayer.offsetHeight}`
      this.listeners.push({ eventName: 'resize', object: window, callback: callback })
      window.addEventListener('resize', callback)

      callback()
    })
  }

  ////////// Video

  /// Prepares the items for the "Video" section.
  prepareVideoItems() {
    this.addHeading('Video')

    if (this.player.currentSource.hlsjs) {
      const updateLevelButtons = () => {
        this.debugLevelAutoButton.toggleAttribute('active', this.player.hls.autoLevelEnabled)
        this.debugLevelButtons.forEach((levelButton, index) => levelButton.toggleAttribute('active', this.player.hls.currentLevel === index))
      }
      this.player.addEventListener('onspace:media:player:hls-level-changed', updateLevelButtons)
      this.listeners.push({ eventName: 'onspace:media:player:hls-level-changed', object: this.player, callback: updateLevelButtons })

      this.addItem('Levels', (element) => {
        element.innerHTML = ''

        if (this.player.hls) {
          const buttonGroup = document.createElement('div')
          buttonGroup.classList.add('onspace-button-group')

          this.debugLevelAutoButton = document.createElement('a')
          this.debugLevelAutoButton.classList.add('onspace-button')
          this.debugLevelAutoButton.classList.add('onspace-button--outline')
          this.debugLevelAutoButton.innerHTML = '<span>Auto</span>'
          this.debugLevelAutoButton.addEventListener('click', () => this.player.hls.nextLevel = -1)
          buttonGroup.appendChild(this.debugLevelAutoButton)

          this.debugLevelButtons = []

          this.player.hls.levels.forEach((level, index) => {
            const levelButton = document.createElement('a')
            levelButton.classList.add('onspace-button')
            levelButton.classList.add('onspace-button--outline')
            levelButton.innerHTML = `<span>${level.width}×${level.height}</span><span>${level.bitrate / 1000}Kb</span>`
            levelButton.addEventListener('click', () => this.player.hls.nextLevel = index)
            buttonGroup.appendChild(levelButton)

            this.debugLevelButtons.push(levelButton)
          })

          element.appendChild(buttonGroup)
          updateLevelButtons()
        }
      }, 'onspace:media:player:hls-levels-changed')

      this.addItem('Next Level', (element) => element.innerText = this.player.hls ? this.player.hls.nextLoadLevel : '', 'onspace:media:player:hls-level-changing')
      this.addItem('Loaded Level', (element) => element.innerText = this.player.hls ? this.player.hls.loadLevel : '', 'onspace:media:player:hls-fragment-loaded')
    } else {
      this.addItem('Resolution', (element) => element.innerText = `${this.player.nativePlayer.videoWidth}×${this.player.nativePlayer.videoHeight}`, 'onspace:media:player:video-resolution-changed')
    }
  }
}

window.customElements.define('onspace-video-debug', OnspaceVideoPlayerDebug)
