import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import Hls from 'hls.js'

/// The Onspace Player Debug element displays debugging information and controls for a media player.
///
/// This class should not be used directly, instead subclass it and override the functionality as required.
export default class OnspacePlayerDebug extends CustomHTMLElement {
  /// Sets up the debug element.
  runConstructor(options = {}) {
    super.runConstructor()

    this.player = options.player

    this.listeners = []
  }

  /// Runs when the element is first connected to the DOM.
  runFirstConnected(_options = {}) {
    super.runFirstConnected()

    this.classList.add('onspace-player-debug')

    this.prepareSourceItems()
    this.preparePlayerItems()
    this.prepareStateItems()
    this.prepareAudioItems()
  }

  /// Runs when the element is disconnected from the DOM.
  disconnectedCallback() {
    super.disconnectedCallback()

    this.listeners.forEach((listener) => listener.object.removeEventListener(listener.eventName, listener.callback))
  }

  ////////// Elements

  /// Adds a heading to the element.
  addHeading(title) {
    const headingElement = document.createElement('div')
    headingElement.classList.add('onspace-player__debug__heading')
    headingElement.innerText = title
    this.appendChild(headingElement)
  }

  /// Adds an item to the element.
  ///
  /// This adds the item with the given title and value. If the value is a function, that function will be called with
  /// the value element as an argument, so that custom content can be applied. It can also be used to listen for events
  /// to automatically update the content. You can also optionally pass the +eventName+, which will automatically listen
  /// for an event on the player, and call the function each time it triggers.
  addItem(title, value, eventName) {
    const element = document.createElement('div')
    element.classList.add('onspace-player__debug__item')

    const titleElement = document.createElement('div')
    titleElement.classList.add('onspace-player__debug__item__title')
    titleElement.innerText = title
    element.appendChild(titleElement)

    const valueElement = document.createElement('div')
    valueElement.classList.add('onspace-player__debug__item__value')
    if (typeof value === 'function') {
      if (eventName) {
        const callback = (event) => value(valueElement, event)
        this.listeners.push({ eventName: eventName, object: this.player, callback: callback })
        this.player.addEventListener(eventName, callback)
      }

      value(valueElement)
    } else {
      valueElement.innerText = value
    }
    element.appendChild(valueElement)

    this.appendChild(element)
  }

  ////////// Source

  /// Prepares the items for the "Source" section.
  prepareSourceItems() {
    this.addHeading('Resource')

    this.addItem('Location', (element) => element.innerHTML = `<a href="${this.player.currentSource.url}" target="_blank">${this.player.currentSource.url}</a>`, 'onspace:media:player:source-changed')
    this.addItem('Content Type', (element) => element.innerText = this.player.currentSource.contentType || 'Unknown', 'onspace:media:player:source-changed')
    this.addItem('Sources', (element) => {
      element.innerHTML = ''

      const buttonGroup = document.createElement('div')
      buttonGroup.classList.add('onspace-button-group')

      this.player.sources.forEach((source, index) => {
        const sourceButton = document.createElement('a')
        sourceButton.classList.add('onspace-button')
        sourceButton.classList.add('onspace-button--outline')
        sourceButton.innerText = source.contentType

        let contentType = null
        let codecs = null

        if (source.contentType) {
          const contentTypeMatch = source.contentType.match(/^([a-z0-9.]+\/[a-z0-9.]+)($|;)/)
          if (contentTypeMatch) {
            contentType = contentTypeMatch[1]
            if (contentType == 'application/vnd.apple.mpegurl') { contentType = 'HLS' }
          }

          const codecsMatch = source.contentType.match(/codecs="([a-zA-Z0-9.,]+)"/)
          if (codecsMatch) {
            codecs = codecsMatch[1].split(',')
            codecs = codecs.map((codec) => {
              if (codec.startsWith('avc1')) {
                return 'AVC'
              } else if (codec.startsWith('hvc1')) {
                return 'HEVC'
              } else if (codec.startsWith('mp4a')) {
                return 'AAC'
              }
            })
            codecs = codecs.join('/')
          }
        }

        contentType ||= 'Unknown'

        sourceButton.innerHTML = `<span>${contentType}</span>`
        if (codecs) {
          sourceButton.innerHTML += `<span>${codecs}</span>`
        }

        if (this.player.sourceIndex == index) {
          sourceButton.setAttribute('active', '')
        }

        sourceButton.addEventListener('click', () => this.player.switchSource(index))

        buttonGroup.append(sourceButton)
      })

      element.appendChild(buttonGroup)
    }, 'onspace:media:player:source-changed')
  }

  ////////// Player

  /// Prepares the items for the "Player" section.
  preparePlayerItems() {
    this.addHeading('Player')

    this.addItem('User Agent', navigator.userAgent)
    this.addItem('Engine', this.player.currentSource.hlsjs ? `hls.js ${Hls.version}` : 'native')
    this.addItem('Position', (element) => element.innerText = `${this.player.position.toFixed(3)} / ${this.player.duration.toFixed(3)}`, 'onspace:media:player:position-changed')
  }

  ////////// State

  /// Prepares the items for the "State" section.
  prepareStateItems() {
    this.addHeading('State')

    this.addItem('Activated', (element) => element.innerText = this.player.activated ? 'Yes' : 'No', 'onspace:media:player:activated-changed')
    this.addItem('Playback', (element) => {
      if (this.player.hasEnded) {
        element.innerText = 'Ended'
      } else if (this.player.isPlaying) {
        element.innerText = 'Playing'
      } else {
        element.innerText = 'Paused'
      }
    }, 'onspace:media:player:playback-changed')
  }

  ////////// Audio

  /// Prepares the items for the "Audio" section.
  prepareAudioItems() {
    this.addHeading('Audio')

    if (this.player.currentSource.hlsjs) {
      const updateTrackButtons = () => {
        this.debugTrackButtons.forEach((trackButton, index) => trackButton.toggleAttribute('active', this.player.hls.audioTrack === index))
      }
      this.player.addEventListener('onspace:media:player:hls-track-changed', updateTrackButtons)
      this.listeners.push({ eventName: 'onspace:media:player:hls-track-changed', object: this.player, callback: updateTrackButtons })

      this.addItem('Tracks', (element) => {
        element.innerHTML = ''

        if (this.player.hls) {
          if (this.player.hls.audioTracks.length == 0) {
            element.innerHTML = 'No Audio'
          }

          const buttonGroup = document.createElement('div')
          buttonGroup.classList.add('onspace-button-group')

          this.debugTrackButtons = []

          this.player.hls.audioTracks.forEach((track, index) => {
            const trackButton = document.createElement('a')
            trackButton.classList.add('onspace-button')
            trackButton.classList.add('onspace-button--outline')
            trackButton.innerHTML = `<span>${track.name}</span>`
            trackButton.addEventListener('click', () => this.player.hls.audioTrack = index)
            buttonGroup.appendChild(trackButton)

            this.debugTrackButtons.push(trackButton)
          })

          element.appendChild(buttonGroup)
          updateTrackButtons()
        }
      }, 'onspace:media:player:hls-tracks-changed')
    }
  }
}
