import { Appearance } from 'react-native'

import styles from '../../../assets/styles/base/color'

/// Retrieves a color by name.
///
/// This will locate a color from the registered styles. The +name+ can be one of the registered aliases in addition to
/// the named colors. If a color can not be found, it will return the +name+ argument. The following additional options
/// are supported:
/// [scheme]
///   Overrides the current color scheme. Supported values are +light+ and +dark+.
/// [type]
///   The type of color to return. Supported values include:
///   - +base+: (default) the standard colour.
///   - +hover+: indicates hovering over interactive elements.
///   - +active+: indicates an interactive element is active, for example pressed.
///   - +disabled+: indicates an element is disabled.
///   - +trans+: a semi-transparent variant of the color.
///   - +bg+: a variant appropriate for use as a background color.
///   - +text+: the text color which should be used to contrast with the base color.
export function getNamedColor(name, options={}) {
  name = styles.nameAliases[name] || name
  const scheme = options.scheme || Appearance.getColorScheme() || 'light'
  const type = options.type || 'base'
  const otherwise = options.default === false ? null : name

  const colorOptions = styles.colors[name]
  if (colorOptions) {
    return colorOptions[scheme][type]
  } else {
    return otherwise
  }
}

/// Retrieves a text color.
///
/// This will retrieve the appropriate text color from the given variant, which should be one of:
/// - +regular+: (default) the base text color for the scheme, black or white.
/// - +reverse+: the opposite text color for the scheme, white or black.
/// - +muted+: a grey color appropriate against the scheme's background colors.
/// - anything else will be returned.
///
/// This also supports the following options:
/// [scheme]
///   Overrides the current color scheme. Supported values are +light+ and +dark+.
export function getTextColor(variant, options={}) {
  variant = variant || 'regular'
  const scheme = options.scheme || Appearance.getColorScheme() || 'light'

  switch (variant) {
  case 'muted':
    return styles.grays[0][scheme]
  case 'reverse':
    return scheme === 'dark' ? styles.black : styles.white
  case 'regular':
    return scheme === 'dark' ? styles.white : styles.black
  default:
    return getNamedColor(variant, { default: false }) || variant
  }
}

/// Retrieves a background color.
///
/// this will locate a background color from the registered styles. The following additional options are supported:
/// [scheme]
///   Overrides the current color scheme. Supported values are +light+ and +dark+.
export function getBackgroundColor(name, options={}) {
  const scheme = options.scheme || Appearance.getColorScheme() || 'light'

  return styles.backgroundColors[name][scheme]
}
