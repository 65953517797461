/// Triggers an event from an element.
///
/// You must pass in the +name+ of the event to be triggered. The +data+ argument can be given, which will be attached
/// to the event so it can be passed into any callbacks.
///   element.triggerEvent('myevent', { foo: 'bar' })
///   element.addEventListener('myevent', (event) => console.log(event.detail))
///
/// The dispatched event is marked as cancelable, meaning callbacks can run +preventDefault+ and +stopPropagation+. This
/// function will return +false+ if the event had been cancelled by any of the callbacks, otherwise will return +true+.
const triggerEvent = function(name, data) {
  const options = {
    cancelable: true,
    bubbles: true
  }
  let event

  if (data !== undefined) {
    options.detail = data
    event = new CustomEvent(name, options)
  } else {
    event = new Event(name, options)
  }

  return this.dispatchEvent(event)
}
window.document.triggerEvent = triggerEvent
HTMLElement.prototype.triggerEvent = triggerEvent

/// Adds an event listener on the document for an element.
///
/// The listener will be bound to the element. The arguments are passed through to +document.addEventListener+.
HTMLElement.prototype.addDocumentBoundEventListener = function(name, listener, options) {
  this._documentBoundEvents = this._documentBoundEvents || {}

  const boundListener = listener.bind(this)
  this._documentBoundEvents[name] = boundListener
  document.addEventListener(name, boundListener, options)
}

/// Remove an event listener from the document for an element.
///
/// The listener must have been previously registered using HTMLElement.prototype.addDocumentBoundEventListener.
HTMLElement.prototype.removeDocumentBoundEventListener = function(name) {
  if (this._documentBoundEvents && this._documentBoundEvents[name]) {
    document.removeEventListener(name, this._documentBoundEvents[name])
    delete this._documentBoundEvents[name]
  }
}

/// Adds an event listener on the window for an element.
///
/// The listener will be bound to the element. The arguments are passed through to +window.addEventListener+
HTMLElement.prototype.addWindowBoundEventListener = function(name, listener, options) {
  this._windowBoundEvents = this._windowBoundEvents || {}

  const boundListener = listener.bind(this)
  this._windowBoundEvents[name] = boundListener
  window.addEventListener(name, boundListener, options)
}

/// Remove an event listener from the window for an element.
///
/// The listener must have been previously registered using HTMLElement.prototype.addWindowBoundEventListener.
HTMLElement.prototype.removeWindowBoundEventListener = function(name) {
  if (this._windowBoundEvents && this._windowBoundEvents[name]) {
    window.removeEventListener(name, this._windowBoundEvents[name])
    delete this._windowBoundEvents[name]
  }
}

/// Adds an event listener for children of this element.
///
/// This will trigger when the listened event occurs on a child that matches the +selector+ argument. The remaining
/// arguments are passed through to +addEventListener+.
///
/// Note that the event must bubble up to this element.
const addEventSelectorListener = function(selector, name, listener, options) {
  this.addEventListener(name, (event) => {
    const selectorElement = event.target.closest(selector)
    if (selectorElement !== null) {
      event.selectedTarget = selectorElement
      listener(event)
    }
  }, options)
}
window.document.addEventSelectorListener = addEventSelectorListener
HTMLElement.prototype.addEventSelectorListener = addEventSelectorListener
