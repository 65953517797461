import React from 'react'

import Svg from 'react-native-svg'

import { getTextColor } from '../components/color'
import { getIconSize } from '../components/layout'

/// A component which displays an SVG.
///
/// The following attributes are supported:
/// [height]
///   The height of the element. This is passed through +getIconSize+.
/// [width]
///   The width of the element. This is passed through +getIconSize+.
/// [size]
///   Sets the height and width of the element. This is passed through +getIconSize+.
/// [color]
///   The default fill color of the SVG. This is passed through +getTextColor+.
export default function OnspaceSvg({ color, size, height, width, children, _spritemap, ...props }) {
  if (!_spritemap) {
    height = height || size
    width = width || size

    if (!!height || !width) { height = getIconSize(height) }
    if (!!width || !height) { width = getIconSize(width) }

    if (color) {
      props.fill = getTextColor(color)
    } else {
      props.fill = 'currentColor'
    }

    props.height = height
    props.width = width
  } else {
    props.style = { fill: 'currentColor' }
  }

  return <Svg {...props}>{children}</Svg>
}

export * from 'react-native-svg'
