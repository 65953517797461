import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// The Onspace Player Selector is a component which allows users to select from a list of media.
export default class OnspacePlayerSelector extends CustomHTMLElement {
  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    this.classList.add('onspace-player-selector')
  }

  ////////// Items

  /// Add an item to the selector.
  ///
  /// This accepts a metadata object, see OnspaceMediaPlayer for more information.
  addItem(item) {
    const itemElement = document.createElement('div')
    itemElement.classList.add('onspace-player-selector__item')
    itemElement.itemId = item.id
    itemElement.addPressEventListeners({ onPress: this.itemClicked.bind(this) })

    const imageElement = document.createElement('div')
    imageElement.classList.add('onspace-player-selector__item__image')
    itemElement.appendChild(imageElement)

    const indicatorIcon = SVGElement.createOnspaceSpritemapSvg()
    indicatorIcon.classList.add('onspace-player-selector__item__indicator')
    imageElement.appendChild(indicatorIcon)

    if (item.artwork) {
      imageElement.style.backgroundImage = `url(${item.artwork})`
    }

    if (item.title) {
      const titleElement = document.createElement('div')
      titleElement.classList.add('onspace-player-selector__item__title')
      titleElement.innerText = item.title
      itemElement.appendChild(titleElement)
    }

    if (item.subtitle) {
      const subtitleElement = document.createElement('div')
      subtitleElement.classList.add('onspace-player-selector__item__subtitle')
      subtitleElement.innerText = item.subtitle
      itemElement.appendChild(subtitleElement)
    }

    itemElement.setIndicatorIcon = (name) => {
      indicatorIcon.setOnspaceSpritemapSvg(name)
    }

    this.appendChild(itemElement)
  }

  /// Responds to clicks on an item element.
  itemClicked(event) {
    this.triggerEvent('onspace:media:player-selector:item-clicked', event.target.itemId)
  }

  /// Retrieves the selectable item elements.
  get itemElements() {
    return Array.from(this.children)
  }
}

window.customElements.define('onspace-player-selector', OnspacePlayerSelector)
