let retainedScrollCount = 0

document.addEventListener('turbo:load', resetScrollingRetention)

const clickCaptureElement = document.createElement('div')
clickCaptureElement.classList.add('onspace-scrollretain__capture')
clickCaptureElement.addEventListener('click', bodyClickListener)

/// Resets the retained scrolling count.
///
/// Note that this will not release scrolling if it is held.
function resetScrollingRetention() {
  retainedScrollCount = 0
}

/// Increases the retained scrolling count.
///
/// If the count was previously 0, this captures the scrolling of the page. A div will be placed over the page which
/// captures clicks. Elements with the class +onspace-scrollretained+ and +onspace-scrollretained-container+ will still
/// be interactive.
function retainDocumentScrolling() {
  if (retainedScrollCount === 0) {
    const dialog = document.querySelector('.onspace-dialog:last-child dialog')
    if (dialog && dialog.open) {
      dialog.classList.add('onspace-scrollretain--active')
      dialog.insertBefore(clickCaptureElement, dialog.firstChild)
    } else {
      document.body.classList.add('onspace-scrollretain--active')
      document.body.insertBefore(clickCaptureElement, document.body.firstChild)
      window.setTimeout(() => document.body.addEventListener('click', bodyClickListener))
    }
  }

  retainedScrollCount += 1
}

/// Indicates if scrolling is currently retained.
export function isScrollingRetained() {
  return retainedScrollCount > 0
}

/// Decreases the retained scrolling count.
///
/// If the new value is 0, this releases the scrolling of the page, performing the opposite function to
/// +retainDocumentScrolling+.
function releaseDocumentScrolling() {
  if (retainedScrollCount <= 1) {
    retainedScrollCount = 0

    const element = document.querySelector('.onspace-scrollretain--active')
    if (element) {
      element.classList.remove('onspace-scrollretain--active')
    }
    clickCaptureElement.remove()

    document.body.removeEventListener('click', bodyClickListener)
  } else {
    retainedScrollCount -= 1
  }
}

/// Listens for clicks on the body while scrolling is retained.
///
/// When fired, this releases scrolling. You can listen for the event +onspace:scrollretain:released+ for clean up. You
/// can also listen and for the event +onspace:scrollretain:releasing+ which can be cancelled to prevent scrolling from
/// releasing.
function bodyClickListener(event) {
  const retval = bodyDisableListener(event)

  if (!retval) {
    const proceed = document.triggerEvent('onspace:scrollretain:releasing')

    if (proceed) {
      retainedScrollCount = 0
      releaseDocumentScrolling()
      document.triggerEvent('onspace:scrollretain:released')

      document.querySelectorAll('.onspace-scrollretained').forEach((retainer) => retainer.onspaceReleaseScrolling())
    }
  }

  return retval
}

/// Checks if a click on the capture element was bubbled from an element which is still interactive.
function bodyDisableListener(event) {
  let target = event.target
  while (target) {
    if (target.retainedScrolling) {
      return true
    }

    target = target.parentElement
  }

  event.preventDefault()
  return false
}

/// Retains scrolling from the current element.
///
/// This marks the current element as interactive.
HTMLElement.prototype.onspaceRetainScrolling = function() {
  if (!this.retainedScrolling) {
    this.retainedScrolling = true
    this.classList.add('onspace-scrollretained')
    retainDocumentScrolling()

    let item = this
    while (item && item !== document.body) {
      item.classList.add('onspace-scrollretained-container')
      item = item.parentElement
    }
  }
}

/// Releases scrolling from the current element.
///
/// This marks the current element as interactive.
HTMLElement.prototype.onspaceReleaseScrolling = function() {
  if (this.retainedScrolling) {
    this.retainedScrolling = false
    this.classList.remove('onspace-scrollretained')
    releaseDocumentScrolling()

    let item = this
    while (item && item !== document.body) {
      item.classList.remove('onspace-scrollretained-container')
      item = item.parentElement
    }
  }
}
