// cspell:words menued

import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import Hls from 'hls.js'

import translation from '@onpace/onspace-core/components/translations'
import dialog from '@onpace/onspace-media/elements/player/dialog'
import { onGoogleCastAvailable } from '@onpace/onspace-media/elements/player/google_cast'

import OnspacePlayerAdvertisement from '@onpace/onspace-media/elements/player/advertisement'
import OnspacePlayerUpNext from '@onpace/onspace-media/elements/player/up_next'

const CHAPTER_TYPE_RECAP = 'recap'
const CHAPTER_TYPE_INTRO = 'intro'
const CHAPTER_TYPE_PREVIEW = 'preview'
const CHAPTER_TYPE_CREDITS = 'credits'
const CHAPTER_TYPES = [CHAPTER_TYPE_RECAP, CHAPTER_TYPE_INTRO, CHAPTER_TYPE_PREVIEW, CHAPTER_TYPE_CREDITS]

/// The Onspace Media Player is an abstract player which can be capable of playing various forms of media.
///
/// This class should not be used directly, instead subclass it and override the functionality as required.
///
/// Players contain persistent functionality, ensuring playback continues across page loads. This is only supported on
/// player elements with a valid +id+ attribute. It will not be triggered by this abstract class, rather it should be
/// managed by a concrete subclass. See OnspacePlayerDialog for more information.
export default class OnspaceMediaPlayer extends CustomHTMLElement {
  /// Sets up the media player element.
  ///
  /// This creates the required children and adds events where necessary.
  ///
  /// The element can be configured either from an object passed through the constructor, or as attributes on the
  /// element. The following options are supported:
  /// [src]
  ///   The source URL of the media.
  /// [sources]
  ///   An array of source objects. See OnspaceMediaPlayer.parseSources for more information.
  /// [sourceLoader]
  ///   An asynchronous function which can load sources. See OnspaceMediaPlayer.loadSources for more information.
  /// [metadata]
  ///   Optional information describing the media. This should be a JSON object, and can contain the following:
  ///   [id]
  ///     An optional identifier unique to the current media.
  ///   [title]
  ///     The title of the media.
  ///   [subtitle]
  ///     The subtitle of the media.
  ///   [artwork]
  ///     An image url which represents the media.
  ///   [chapters]
  ///     An array of chapter metadata, which breaks down the media into sections. When provided, there must be data for
  ///     the entire timeline of the media. Each is an object containing the following:
  ///     [title]
  ///       The title of the chapter.
  ///     [startTime]
  ///       Indicates when the current chapter begins, in seconds. This must be +0+ for the first chapter. Each chapter
  ///       ends at the start of the next one, or at the end of the media for the last chapter.
  ///     [type]
  ///       An optional chapter type which can cause the player to behave differently. Available values are:
  ///       [+recap+]
  ///         A recap of previous media. During playback, a "Skip Recap" button will appear, allowing the user to skip
  ///         to the next chapter.
  ///       [+intro+]
  ///         An introduction to the media. During playback, a "Skip Intro" button will appear, allowing the user to
  ///         skip to the next chapter.
  ///       [+preview+]
  ///         A preview of the next media. During playback, a "Skip Preview" button will appear allowing the user to
  ///         skip to the next chapter.
  ///       [+credits+]
  ///         Indicates the credits are playing at the end of the media. If this is the last chapter, this will trigger
  ///         the "Up Next" functionality in the player, if configured. If there are other chapters, a "Skip Credits"
  ///         button will appear.
  ///       [blank]
  ///         All other content.
  ///
  ///   This will be automatically updated when the player changes the video, and can be updated using the +metadata+
  ///   setter.
  /// [tabs]
  ///   Adds additional UI to the controls. These appear as tab buttons, with their content initially hidden. This
  ///   should be an array of objects, with the following keys:
  ///   [title]
  ///     The text of the button which will appear in the controls.
  ///   [actions]
  ///     Where supported by the type, adds buttons which will appear in the interface. This should be an array of
  ///     objects, each with the following:
  ///     [icon]
  ///       An icon which will be displayed within the button.
  ///     [title]
  ///       The text of the button.
  ///     [callback]
  ///       A function which will be called when clicking on the button. This may include arguments depending on the
  ///       type of tab.
  ///   [type]
  ///     Indicates the UI which will be displayed. Each type may support additional attributes. The following types are
  ///     supported:
  ///     [showcase]
  ///       Showcases a single media item. This supports the +actions+ attribute, and uses the following additional
  ///       attributes:
  ///       [metadata]
  ///         Information describing the media.
  ///     [selector]
  ///       Presents a scrcolling horizontal list of media. This supports a single action in the +actions+ attribute,
  ///       which will be used as the primary click on each item. If not provided, the default action is to play the
  ///       clicked item. It uses the following additional attributes:
  ///       [items]
  ///         An array of metadata objects. If an item has the same +id+ as the player's current item, it will be
  ///         indicated as such.
  ///     [custom]
  ///       Custom UI which is provided by the application. This uses the following attributes:
  ///       [create]
  ///         A callback which creates the UI for the tab. This will only be called once, and should return a HTML
  ///         element.
  ///       [update]
  ///         A callback which will be run whenever the content will be shown. This will be called with the created
  ///         element as an argument.
  ///
  ///   Tabs will be maintained when the video changes, and can be updated using +setTabs+.
  /// [upNext]
  ///   Provides the information for media which will play at the end of the current media. This should be an object
  ///   containing the following:
  ///   [sources]
  ///     An array of source objects. The player will reuse the +sourcesLoader+ if available.
  ///   [metadata]
  ///     Information describing the media. This is optional, but highly recommended. When present, an "Up Next" tab
  ///     will automatically be added.
  ///   [automaticPlayInterval]
  ///     The duration in seconds after which the player will start playing the next media. By default, this is set to
  ///     10. Set this to +false+ to require manual interaction.
  ///
  ///   When the current video changes, this will be cleared, but can be updated using +setUpNext+.
  /// [autoplay]
  ///   When +true+, the media will start as soon as the player enters the DOM. This is +false+ by default.
  ///
  ///   Note that most browsers will prevent auto-playing audio unless it was triggered via a user interaction. See
  ///   OnspaceMediaPlayer.resumePlayback for more info.
  /// [analytics]
  ///   Optional information sent with analytics events. This should be a JSON object containing the following:
  ///   [record_type]
  ///     The class name of the record that is being viewed. This should map to an equivalent Rails class.
  ///   [record_id]
  ///     The id of the record that is being viewed.
  ///   [subject]
  ///     An optional short descriptor indicating the type of content in the media, such as "replay" or "preview".
  ///   [player_type]
  ///     An optional short descriptor indicating the mode of playback.
  ///
  ///     This is set to "inline" by default.
  /// [closeButton]
  ///   A callback to run for a close button. When provided, this will add a close button to the UI where necessary.
  ///
  /// You can also provide multiple source locations by nesting +<source>+ elements within the player element. See
  /// OnspaceMediaPlayer.detectSourceElements for more information.
  runConstructor(_options = {}) {
    super.runConstructor()

    this._isLoading = false

    this.addPressEventListeners({
      onPress: this.pressed.bind(this),
      onLongPress: this.longPressed.bind(this)
    })
  }

  /// Runs when the media player is first connected to the DOM.
  ///
  /// If the player is configured to autoplay, this will start the player.
  runFirstConnected(options = {}) {
    super.runFirstConnected()

    this.multiScreen = options.multiScreen || null

    const sources = options.sources || this.getJsonAttribute('data-sources')
    if (sources) {
      this.sourcesData = sources
    } else if (options.src) {
      this.sourcesData = [{ url: options.src, contentType: null }]
    }
    this.sourcesLoader = this.sourcesLoader || options.sourcesLoader || null
    this.metadata = options.metadata || this.getJsonAttribute('metadata') || {}

    this.setTabs(options.tabs)
    this.setUpNext(options.upNext)

    this.classList.add('onspace-player')

    this.autoplay = options.autoplay || this.getBooleanAttribute('autoplay')

    this.closeButtonCallback = options.closeButton

    this.analyticsParams = options.analytics || this.getJsonAttribute('analytics') || {}
    this.configureAnalytics()

    this.setupContent()
    this.setupControls()
    this.detectDebugMode()

    onGoogleCastAvailable(() => {
      this.setupGoogleCast()
    })

    if (this.autoplay) {
      this.startPlayer()
    }
  }

  /// Runs when the media player is connected to the DOM.
  runConnected() {
    super.runConnected()

    this.addWindowBoundEventListener('beforeunload', this.beforeWindowUnloaded)

    if (!this.multiScreen) {
      this.keysDown = []
      this.addDocumentBoundEventListener('keydown', this.documentKeyDowned)
      this.addDocumentBoundEventListener('keyup', this.documentKeyUpped)
    }
  }

  /// Runs when the media player is disconnected from the DOM.
  ///
  /// This cleans up events relating to the document.
  runDisconnected() {
    super.runDisconnected()

    if (!this.isPersisted) {
      this.destroyPlayer()
    }

    this.removeWindowBoundEventListener('beforeunload')

    this.removeDocumentBoundEventListener('keydown')
    this.removeDocumentBoundEventListener('keyup')
  }

  ////////// Source

  /// Loads the sources for the player.
  ///
  /// This will attempt to load the sources in the following order:
  /// - Asynchronously from the +sourcesLoader+ callback, which is initialised in the constructor from the
  ///   +sourcesLoader+ option.
  /// - From the +sourcesData+ attribute, which is initialised in the constructor from the +src+ or +sources+ options.
  /// - From +<source>+ elements inside the player.
  ///
  /// When using an asynchronous function, it should return an object, which can include the following attributes:
  /// [sources]
  ///   An array of source objects. See OnspaceMediaPlayer.parseSources for more information.
  /// [advertisement]
  ///   Indicates that the source is an advertisement that has been injected. This should be an object, see
  ///   OnspacePlayerAdvertisement for supported parameters.
  /// [error]
  ///   A string containing an error message. This will be thrown as an error.
  ///
  /// It will be called with a single argument, containing the following options:
  /// [mediaId]
  ///   If available, this is the +id+ value from the media's +metadata+.
  /// [resume]
  ///   When +true+, indicates that the player is expecting to resume the previously played media, for example when
  ///   handing off playback to an external player.
  /// [playedAdvertisement]
  ///   Indicates that the player has completed playback of media marked as an advertisement.
  ///
  /// If a +sources+ attribute has already been assigned, this will do nothing. If no sources can be found, this will
  /// throw an error.
  async loadSources(options = {}) {
    if (typeof this.sources !== 'undefined') { return }

    let sources = null
    let advertisement = null

    if (this.sourcesLoader) {
      const loaderOptions = {
        resume: options.resume,
        playedAdvertisement: this.playedAdvertisement
      }

      if (this.metadata && typeof this.metadata.id === 'string') {
        loaderOptions.mediaId = this.metadata.id
      }

      try {
        const loadedSources = await this.sourcesLoader(loaderOptions)
        if (loadedSources.error) { throw new Error(loadedSources.error) }

        sources = this.parseSources(loadedSources.sources)
        advertisement = loadedSources.advertisement
      } catch {
        // ignore
      }
    } else if (this.sourcesData) {
      sources = this.parseSources(this.sourcesData)
    } else {
      sources = this.detectSourceElements()
    }

    if (!Array.isArray(sources) || sources.length === 0) {
      throw new Error(translation('onspace.media.player.error.source_missing'))
    }

    this.sources = sources
    this.sourceIndex = 0

    if (advertisement !== null && typeof advertisement === 'object') {
      this.configureAdvertisement(advertisement)
    }
  }

  /// Detects the available sources for the player.
  ///
  /// This finds +<source>+ elements nested within the player, then parses them using parseSources().
  detectSourceElements() {
    const sourceElements = Array.from(this.querySelectorAll('source'))
    const sourceObjects = sourceElements.map((source) => {
      return { url: source.src, contentType: source.type }
    })

    return this.parseSources(sourceObjects)
  }

  /// Determines is a source object can be played.
  ///
  /// This inspects the source's +contentType+ attribute to determine it's playability. It utilises
  /// HTMLMediaElement.canPlayType, and will return one of the following:
  /// - +probably+
  /// - +maybe+
  /// - an empty string
  canPlaySource(sourceObject) {
    const tester = document.createElement('video')

    let contentType = sourceObject.contentType
    if (typeof contentType !== 'string' || contentType.length === 0) { return 'unknown' }

    if (sourceObject.hlsjs) {
      contentType = contentType.replace('application/vnd.apple.mpegurl', 'video/mp4')
    }

    return tester.canPlayType(contentType)
  }

  /// Determines the playability of source objects for the player.
  ///
  /// This analyses the given +sourceObjects+ to determine which can be played and set an order of priority. Each object
  /// should contain the following attributes:
  /// [url]
  ///   The URL or path to the source.
  /// [contentType]
  ///   The MIME type of content contained in the source. This is optional, but each object should include one for the
  ///   most effective prioritisation. These should include codecs where possible, either:
  ///   - As part of the MIME type, in the format +MIME/TYPE; codecs="codec1,codec2"+
  ///   - As separate attributes on the object, +videoCodec+ and +audioCodec+.
  ///
  ///   Including the codec is important where you have multiple codec variants using the same container. For example,
  ///   you might have two HLS streams, one encoded using AVC1 and another in HEVC.
  ///
  /// Sources will be prioritised in the following order:
  ///   1. Content types that return "probably" from canPlaySource().
  ///   2. Content types that return "maybe" from canPlaySource().
  ///   3. Sources without a content type.
  ///   4. If multiple sources fit the above priorities, they will be used in the order they appear in the DOM, from top
  ///      to bottom.
  ///
  /// Sources with a content type that returns an empty string from canPlaySource() will be removed.
  parseSources(sourceObjects) {
    const probablySources = []
    const maybeSources = []
    const unknownSources = []

    sourceObjects.forEach((sourceObject) => {
      let contentType = sourceObject.contentType || sourceObject.content_type || null

      if (contentType) {
        const codecs = []

        const videoCodec = sourceObject.videoCodec || sourceObject.video_codec
        if (videoCodec) { codecs.push(videoCodec) }

        const audioCodec = sourceObject.audioCodec || sourceObject.audio_codec
        if (audioCodec) { codecs.push(audioCodec) }

        if (codecs.length > 0) { contentType += `; codecs="${codecs.join(',')}"` }
      }

      const parsedSource = {
        url: sourceObject.url,
        contentType: contentType
      }

      if (Hls.isSupported()) {
        try {
          const url = new URL(parsedSource.url)
          parsedSource.hlsjs = url.pathname.endsWith('.m3u8')
        } catch (_error) {
          parsedSource.hlsjs = false
        }
      } else {
        parsedSource.hlsjs = false
      }

      switch (this.canPlaySource(parsedSource)) {
      case 'probably':
        probablySources.push(parsedSource)
        break
      case 'maybe':
        maybeSources.push(parsedSource)
        break
      case 'unknown':
        unknownSources.push(parsedSource)
        break
      }
    })

    return [...probablySources, ...maybeSources, ...unknownSources]
  }

  /// Retrieves the current player source.
  get currentSource() {
    return this.sources[this.sourceIndex]
  }

  /// Change to use a different source.
  ///
  /// This destroys the player and creates a new one, which consequently restarts playback from the beginning.
  switchSource(index) {
    if (index == this.sourceIndex) { return }

    this.destroyPlayer()
    this.sourceIndex = index
    this.startPlayer()

    this.triggerEvent('onspace:media:player:source-changed')
  }

  ////////// Elements

  /// Sets up the content element.
  setupContent() {
    this.contentElement = document.createElement('div')
    this.contentElement.classList.add('onspace-player__content')

    this.appendChild(this.contentElement)
  }

  ////////// Events

  ///// Interaction

  /// Responds to presses on the element.
  pressed(_event) {}

  /// Responds to long presses on the element.
  ///
  /// This enables debug mode, if it is not already.
  longPressed(_event) {
    this.toggleDebugMode()
  }

  /// Responds to the browser page closing.
  beforeWindowUnloaded(_event) {
    this.destroyPlayer()
  }

  ///// Keyboard

  /// Callback for keyboard presses on the document.
  ///
  /// This checks and ignores any repeated key presses until the key is lifted. It then delegates functionality to
  /// +keyPressBegan+.
  ///
  /// Note that this will only be triggered when the menu is active.
  documentKeyDowned(event) {
    if (!this.activated || this.keysDown.includes(event.key)) { return }

    this.keysDown.push(event.key)

    this.keyPressBegan(event)
  }

  /// Callback for keyboard releases on the document.
  ///
  /// This delegates functionality to +keyPressEnded+.
  documentKeyUpped(event) {
    if (!this.activated) { return }

    const index = this.keysDown.indexOf(event.key)
    this.keysDown.splice(index, 1)

    this.keyPressEnded(event)
  }

  /// Responds to keyboard presses beginning on the document.
  keyPressBegan(event) {
    if (this.playingAdvertisement) { return }

    switch (event.key) {
    case ' ':
    case 'Enter':
      this.togglePlayback()
      break
    case 'Escape':
      if (this.showingUpNext) {
        this.removeUpNext()
      } else if (this.controlsElement.tabsElement.expanded) {
        this.controlsElement.tabsElement.selectedTabIndex = null
      } else {
        return
      }

      break
    case 'ArrowLeft':
      this.beginRepeatedlyAdjustingPosition(-10, 250)
      break
    case 'ArrowRight':
      this.beginRepeatedlyAdjustingPosition(10, 250)
      break
    case 'ArrowDown':
      this.beginRepeatedlyAdjustingAudioVolume(-0.1, 250)
      break
    case 'ArrowUp':
      this.beginRepeatedlyAdjustingAudioVolume(0.1, 250)
      break
    case 'd':
      this.dKeyPressTimeout = setTimeout(() => this.toggleDebugMode(), 3000)
      break
    case 'm':
      this.toggleAudio()
      break
    case 's':
      if (this.showingUpNext) {
        this.startNextPlayer()
      } else {
        this.skipChapter()
      }
      break
    default:
      return
    }

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  /// Responds to keyboard presses ending on the document.
  keyPressEnded(event) {
    switch (event.key) {
    case 'ArrowLeft':
    case 'ArrowRight':
      this.endRepeatedlyAdjustingPosition()
      break
    case 'ArrowDown':
    case 'ArrowUp':
      this.endRepeatedlyAdjustingAudioVolume()
      break
    case 'd':
      clearTimeout(this.dKeyPressTimeout)
      this.dKeyPressTimeout = null
      break
    default:
      return
    }

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  ////////// Actions

  /// Initialises and starts the media player.
  ///
  /// This accepts the following parameters:
  /// [resume]
  ///   Optional metadata indicating to resume playback from a particular position. This should be an object with the
  ///   following:
  ///   [isPlaying]
  ///     Indicates if playback should be started or paused.
  ///   [position]
  ///     Indicates the position to begin playback from, in seconds.
  async startPlayer(options = {}) {
    if (this.playbackStarted) { return }

    delete this.googleCastLastIsPlaying
    delete this.googleCastLastPosition

    this.playbackStarted = true
    this.reportAnalyticsEvent('play')
    this.triggerEvent('onspace:media:player:playback-started')

    this.isLoading = true
    this.setupControls()
    this.clearMessages()

    try {
      await this.loadSources({ resume: !!options.resume })
    } catch (error) {
      return this.showCoverMessage(error.message)
    }

    this.setupNativePlayer()
    this.setupChapters()

    this.activate({ resume: false })

    if (this.googleCastConnected) {
      this.startPlayerGoogleCast(options)
    } else if (this.currentSource.hlsjs) {
      this.startPlayerHls(options)
    } else {
      this.startPlayerNative(options)
    }
  }

  /// Initialises and resumes the media player using an existing playback session.
  ///
  /// This sets up the elements required for playback, but does not actually begin playback.
  continuePlayer() {
    if (this.playbackStarted) { return }

    this.playbackStarted = true
    this.triggerEvent('onspace:media:player:playback-started')

    this.setupControls()
    this.clearMessages()
    this.setupNativePlayer()
    this.setupChapters()

    this.activate({ resume: false })
  }

  /// Stops the current player and reloads from the source.
  ///
  /// This partially destroys the player, keeping reusable components. Note that this will clear any cached sources to
  /// reload them again, calling the +sourcesLoader+ if necessary.
  ///
  /// The following options are supported:
  /// [resume]
  ///   Indicates that the restarted player should resume at the same state as the current player. This is +false+ by
  ///   default.
  restartPlayer({ resume = false } = {}) {
    if (!this.playbackStarted) { return }

    let resumptionMetadata = null
    if (resume) {
      resumptionMetadata = {
        isPlaying: (this.googleCastLastIsPlaying || this.isPlaying),
        position: (this.googleCastLastPosition || this.position)
      }
    }

    this.clearMessages()

    if (this.currentSource.hlsjs) {
      this.stopPlayerHls()
    } else {
      this.stopPlayerNative()
    }

    this.playbackStarted = false
    this.isLoading = true
    delete this.sources

    this.startPlayer({ resume: resumptionMetadata })
  }

  /// Stops and destroys the media player components.
  destroyPlayer() {
    if (!this.playbackStarted) { return }

    this.playbackStarted = false
    this.isLoading = false

    this.clearMessages()

    this.destroyControls()
    this.destroyHlsPlayer()
    this.destroyNativePlayer()
    this.destroyGoogleCastPlayer()
  }

  /// Stops the current player and reloads with new given media.
  ///
  /// Either an +src+ or +sources+ needs to be passed, unless a +sourcesLoader+ is already available. This accepts the
  /// following parameters:
  /// [src]
  ///   The source URL of the media.
  /// [sources]
  ///   An array of source objects. See OnspaceMediaPlayer.parseSources for more information.
  /// [metadata]
  ///   Optional information describing the media. See OnspaceMediaPlayer.runConstructor for more information.
  /// [upNext]
  ///   Provides information for the media which will play at the end of this media. See
  ///   OnspaceMediaPlayer.runConstructor for more information.
  replaceMedia(options={}) {
    if (options.sources) {
      this.sourcesData = options.sources
    } else if (options.src) {
      this.sourcesData = [{ url: options.src, contentType: null }]
    }

    this.metadata = options.metadata
    this.setUpNext(options.upNext)

    delete this.chapters

    this.restartPlayer()
    this.triggerEvent('onspace:media:player:media-changed')
  }

  /// Handles a fatal error in the media player.
  ///
  /// This destroys the player, and shows the given cover message.
  handleFatalError(message) {
    this.destroyPlayer()

    this.showCoverMessage(message, { actions: [
      { title: translation('onspace.media.player.error.retry'), callback: this.startPlayer.bind(this) }
    ] })
  }

  ////////// Native Player

  ///// Element

  /// Initialises and configures the native player element.
  setupNativePlayer() {
    if (this.nativePlayer) { return }

    this.nativePlayer = this.createNativePlayerElement()

    this.nativePlayer.addEventListener('loadedmetadata', this.nativePlayerLoadedMetadata.bind(this))
    this.nativePlayer.addEventListener('waiting', this.nativePlayerWaiting.bind(this))
    this.nativePlayer.addEventListener('canplay', this.nativePlayerCanPlay.bind(this))
    this.nativePlayer.addEventListener('error', this.nativePlayerErrored.bind(this))

    this.nativePlayer.addEventListener('contextmenu', this.nativePlayerContextMenued.bind(this))

    this.nativePlayer.addEventListener('play', this.nativePlayerPlayed.bind(this))
    this.nativePlayer.addEventListener('playing', this.nativePlayerPlaying.bind(this))
    this.nativePlayer.addEventListener('pause', this.nativePlayerPaused.bind(this))
    this.nativePlayer.addEventListener('suspend', this.nativePlayerSuspended.bind(this))
    this.nativePlayer.addEventListener('ended', this.nativePlayerEnded.bind(this))
    this.nativePlayer.addEventListener('timeupdate', this.nativePlayerUpdatedTime.bind(this))

    this.nativePlayer.addEventListener('volumechange', this.nativePlayerVolumeChanged.bind(this))

    this.nativePlayer.addEventListener('webkitplaybacktargetavailabilitychanged', this.nativePlayerAirplayAvailabilityChanged.bind(this))
    this.nativePlayer.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', this.nativePlayerAirplayTargetChanged.bind(this))

    this.contentElement.prepend(this.nativePlayer)
  }

  /// Creates the native player DOM element.
  ///
  /// The default implementation of this raises an error, this should be overridden in a subclass.
  createNativePlayerElement() {
    throw 'This method must be implemented in a subclass'
  }

  /// Removes the native player DOM element.
  destroyNativePlayer() {
    if (!this.nativePlayer) { return }

    this.nativePlayer.remove()
    this.nativePlayer = null
  }

  ///// Actions

  /// Initialises and starts the media player using native functionality.
  ///
  /// This requires that the native player element has already been initialised.
  ///
  /// This supports the same options as OnspaceMediaPlayer.startPlayer.
  startPlayerNative(options={}) {
    this.nativePlayer.src = this.currentSource.url
    this.nativePlayer.load()

    if (typeof options.resume === 'object') {
      this.nativeResumeMetadata = options.resume
    }
  }

  /// Stops the current media player source without destroying it.
  stopPlayerNative() {
    this.nativePlayer.removeAttribute('src')
    this.nativePlayer.load()
  }

  ///// Events

  /// Responds to the native player loading metadata.
  ///
  /// This starts playback by calling +resumePlayback+.
  nativePlayerLoadedMetadata(_event) {
    if (this.hls) { return }

    if (this.nativeResumeMetadata) {
      this.position = this.nativeResumeMetadata.position

      if (this.nativeResumeMetadata.isPlaying) {
        this.resumePlayback()
      }

      this.nativeResumeMetadata = null
    } else {
      this.resumePlayback()
    }

    this.detectLoading()
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  /// Responds to the native player waiting for data.
  nativePlayerWaiting(_event) {
    this.detectLoading()
  }

  /// Responds to the native player estimating whether is ready to play the media.
  nativePlayerCanPlay(_event) {
    this.detectLoading()
  }

  /// Responds to the native player ready to start after waiting for data.
  nativePlayerPlaying(_event) {
    this.detectLoading()
  }

  /// Responds to the native player throwing an error.
  ///
  /// This attempts to handle the error, but if that fails it will trigger a fatal error.
  nativePlayerErrored(event) {
    const playerError = this.nativePlayer.error || {}

    switch (playerError.code) {
    case MediaError.MEDIA_ERR_ABORTED:
      console.error('The media playback was aborted.', playerError) // eslint-disable-line no-console
      break
    case MediaError.MEDIA_ERR_DECODE:
      console.error('The media playback was aborted due to a corruption problem or because the media used features your browser did not support.', playerError) // eslint-disable-line no-console
      if (this.hls) {
        return this.handleHlsMediaError()
      }
      break
    case MediaError.MEDIA_ERR_NETWORK:
      console.error('A network error caused the media download to fail part-way', playerError) // eslint-disable-line no-console
      break
    case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
      console.error('The media could not be loaded, either because the server or network failed, or because the format is not supported.', playerError) // eslint-disable-line no-console
      break
    default:
      console.error('An error occurred with the player element.', event) // eslint-disable-line no-console
      break
    }

    this.handleFatalError(translation('onspace.media.player.error.unknown_fatal'))
  }

  /// Responds to the native source element throwing an error.
  ///
  /// This triggers a fatal error.
  nativePlayerSourceErrored(event) {
    console.error('An error occurred loading the player source', event) // eslint-disable-line no-console

    this.handleFatalError(translation('onspace.media.player.error.source_load_error'))
  }

  /// Responds to context menu events on the native player element.
  ///
  /// This prevents the default context menu from appearing.
  nativePlayerContextMenued(event) {
    event.preventDefault()
    return false
  }

  /// Responds to the native player resuming playback.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerPlayed(_event) {
    this.triggerEvent('onspace:media:player:playback-changed')
  }

  /// Responds to the native player pausing playback.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerPaused(_event) {
    this.triggerEvent('onspace:media:player:playback-changed')
  }

  /// Responds to the native player suspending the loading of data.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerSuspended(_event) {}

  /// Responds to the native player ending playback.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerEnded(_event) {
    this.triggerEvent('onspace:media:player:playback-ended')

    if (this.playingAdvertisement) {
      this.advertisementCompleted()
    } else {
      this.showUpNext()
    }
  }

  /// Responds to the native player changing volume.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerVolumeChanged(_event) {
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  /// Responds to the native player updating its time position.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerUpdatedTime(_event) {
    this.detectChapter()

    this.triggerEvent('onspace:media:player:position-changed')
  }

  /// Responds to the native player updating its airplay availability.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerAirplayAvailabilityChanged(event) {
    this.nativePlayerAirplayAvailable = event.availability === 'available'

    this.triggerEvent('onspace:media:player:remote-changed')
  }

  /// Responds to the native player updating its airplay target device.
  ///
  /// This triggers an event on the player to notify of the update.
  nativePlayerAirplayTargetChanged(_event) {
    this.triggerEvent('onspace:media:player:remote-changed')
  }

  ////////// HLS

  /// Retrieves the configuration for HLS.js.
  ///
  /// This can be overridden in a subclass to customise.
  get hlsConfig() {
    return {
      autoStartLoad: false,
      enableWorker: true,
      maxBufferLength: 30,
      maxMaxBufferLength: 30,
      liveDurationInfinity: true,
      liveSyncDurationCount: 1
    }
  }

  ///// Actions

  /// Initialises and starts the media player using HLS.js.
  ///
  /// This requires that the native player element has already been initialised.
  ///
  /// This supports the same options as OnspaceMediaPlayer.startPlayer.
  startPlayerHls(options = {}) {
    if (!this.hls) {
      this.hls = new Hls(this.hlsConfig)

      this.hls.on(Hls.Events.MEDIA_ATTACHED, this.hlsMediaAttached.bind(this))
      this.hls.on(Hls.Events.MANIFEST_PARSED, this.hlsManifestParsed.bind(this))
      this.hls.on(Hls.Events.ERROR, this.hlsErrored.bind(this))

      this.hls.on(Hls.Events.LEVEL_UPDATED, this.hlsLevelUpdated.bind(this))
      this.hls.on(Hls.Events.LEVEL_SWITCHING, this.hlsLevelSwitching.bind(this))
      this.hls.on(Hls.Events.LEVEL_SWITCHED, this.hlsLevelSwitched.bind(this))
      this.hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, this.hlsAudioTracksUpdated.bind(this))
      this.hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, this.hlsAudioTrackSwitched.bind(this))
      this.hls.on(Hls.Events.FRAG_LOADED, this.hlsFragmentLoaded.bind(this))
    }

    this.hls.attachMedia(this.nativePlayer)

    if (typeof options.resume === 'object') {
      this.hlsResumeMetadata = options.resume
    }
  }

  /// Stops the current media player source without destroying it.
  stopPlayerHls() {
    if (!this.hls) { return }

    this.hls.detachMedia()
  }

  /// Stops and destroys the HLS.js instance.
  destroyHlsPlayer() {
    if (!this.hls) { return }

    this.hls.destroy()
    this.hls = null
  }

  /// Loads the source URL into HLS.js.
  loadHlsSource() {
    this.hls.loadSource(this.currentSource.url)
  }

  ///// Events

  /// Responds to media attachment events from HLS.js.
  ///
  /// This calls +loadHlsSource+.
  hlsMediaAttached(_event, _data) {
    this.loadHlsSource()
  }

  /// Responds to manifest parse events from HLS.js.
  ///
  /// This starts playback by calling +resumePlayback+.
  hlsManifestParsed(_event, _data) {
    this.triggerEvent('onspace:media:player:hls-levels-changed')
    this.triggerEvent('onspace:media:player:hls-tracks-changed')
    this.triggerEvent('onspace:media:player:volume-changed')

    if (this.hlsResumeMetadata) {
      this.hls.startLoad(this.hlsResumeMetadata.position)

      if (this.hlsResumeMetadata.isPlaying) {
        this.resumePlayback()
      }

      this.hlsResumeMetadata = null
    } else {
      this.hls.startLoad()
      this.resumePlayback()
    }
  }

  /// Responds to a hls level's details updating after it's initial load.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsLevelUpdated(_event, _data) {
    this.triggerEvent('onspace:media:player:hls-levels-changed')
  }

  /// Responds to the hls level requesting a change.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsLevelSwitching(_event, data) {
    setTimeout(() => this.triggerEvent('onspace:media:player:hls-level-changing', data))
  }

  /// Responds to the currently playing hls level updating.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsLevelSwitched(_event, data) {
    setTimeout(() => this.triggerEvent('onspace:media:player:hls-level-changed', data))
  }

  /// Responds to the hls audio tracks updating.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsAudioTracksUpdated(_event, _data) {
    this.triggerEvent('onspace:media:player:hls-tracks-changed')
  }

  /// Responds to the currently playing audio track changing.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsAudioTrackSwitched(_event, _data) {
    this.triggerEvent('onspace:media:player:hls-track-changed')
  }

  /// Responds to a hls segment completing a load.
  ///
  /// This triggers an event on the player to notify of the updates.
  hlsFragmentLoaded(_event, _data) {
    this.triggerEvent('onspace:media:player:hls-fragment-loaded')
  }

  /// Responds to errors from HLS.js.
  ///
  /// This will attempt to recover from certain errors, but if that fails it will trigger a fatal error.
  hlsErrored(_event, data) {
    if (data.fatal === true) {
      switch (data.type) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        console.error('fatal network error, trying to recover', data) // eslint-disable-line no-console
        switch (data.details) {
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
          this.handleFatalError(translation('onspace.media.player.error.source_unavailable'))
          break
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
          this.handleFatalError(translation('onspace.media.player.error.source_load_error'))
          break
        default:
          this.hls.startLoad()
          break
        }
        return
      case Hls.ErrorTypes.MEDIA_ERROR:
        console.error('fatal media error, trying to recover', data) // eslint-disable-line no-console
        this.handleHlsMediaError()
        return
      case Hls.ErrorTypes.OTHER_ERROR:
        if (data.details === Hls.ErrorDetails.INTERNAL_EXCEPTION) {
          console.error('internal hls error', data) // eslint-disable-line no-console
          return
        }
        break
      }

      console.error('fatal error, unable to recover', data) // eslint-disable-line no-console
      this.handleFatalError(translation('onspace.media.player.error.unknown_fatal'))
    } else {
      console.warn(data) // eslint-disable-line no-console
    }
  }

  /// Responds to HLS.js media errors.
  ///
  /// This attempts to recover from the error, but if it fails will trigger a fatal error.
  handleHlsMediaError() {
    const now = new Date()
    if (!this.recoverDecodingErrorDate || (now - this.recoverDecodingErrorDate) > 3000) {
      this.recoverDecodingErrorDate = now
      this.hls.recoverMediaError()
    } else if (!this.recoverSwapAudioCodecDate || (now - this.recoverSwapAudioCodecDate) > 3000) {
      this.recoverSwapAudioCodecDate = now
      this.hls.swapAudioCodec()
      this.hls.recoverMediaError()
    } else {
      this.handleFatalError(translation('onspace.media.player.error.unknown_fatal'))
    }
  }

  ////////// Google Cast

  /// Initialises the Google Cast framework for this player.
  setupGoogleCast() {
    this.googleCastMediaId = this.id || 'onspace-media-player'
    this.googleCastPlayer = new window.cast.framework.RemotePlayer()
    this.googleCastController = new window.cast.framework.RemotePlayerController(this.googleCastPlayer)

    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, this.googleCastConnectedChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED, this.googleCastMediaLoadedChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED, this.googleCastPausedChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, this.googleCastCurrentTimeChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.CAN_CONTROL_VOLUME_CHANGED, this.googleCastCanControlVolumeChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, this.googleCastVolumeMutedChanged.bind(this))
    this.googleCastController.addEventListener(window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, this.googleCastCurrentVolumeChanged.bind(this))

    this.triggerEvent('onspace:media:player:remote-changed')

    if (this.googleCastActive) {
      this.continuePlayerGoogleCast()
    }
  }

  /// Retrieves the current Google Cast session.
  get googleCastSession() {
    return window.cast.framework.CastContext.getInstance().getCurrentSession()
  }

  /// Initialises and starts the media player using Google Cast.
  ///
  /// This supports the same options as OnspaceMediaPlayer.startPlayer.
  async startPlayerGoogleCast(options = {}) {
    this.googleCastController.stop()

    this.showOverlayMessage(translation('onspace.media.player.playback.media_remote'), { icon: 'onspace/player_google_cast' })

    const mediaInfo = new window.chrome.cast.media.MediaInfo(this.googleCastMediaId, this.currentSource.contentType)
    mediaInfo.contentUrl = this.currentSource.url

    mediaInfo.metadata = new window.chrome.cast.media.MovieMediaMetadata()
    if (this.metadata.title) { mediaInfo.metadata.title = this.metadata.title }
    if (this.metadata.subtitle) { mediaInfo.metadata.subtitle = this.metadata.subtitle }
    if (this.metadata.artwork) { mediaInfo.metadata.images = [new window.chrome.cast.Image(this.metadata.artwork)] }

    const request = new window.chrome.cast.media.LoadRequest(mediaInfo)

    try {
      await this.googleCastSession.loadMedia(request)
      this.isLoading = false
    } catch (error) {
      console.error('The Google Cast session failed.', error) // eslint-disable-line no-console
      this.handleFatalError(translation('onspace.media.player.error.unknown_fatal'))
    }

    if (typeof options.resume === 'object') {
      if (options.resume.isPlaying) {
        this.resumePlayback()
        this.googleCastLastIsPlaying = true
      } else {
        this.pausePlayback()
      }

      this.position = options.resume.position
    }
  }

  /// Initialises and resumes the media player using an existing Google Cast session.
  ///
  /// When the player loads, this will be called to detect if the media was launched previously, and then the player was
  /// navigated away from. It will takeover the existing playback session from the current position.
  continuePlayerGoogleCast() {
    const mediaStatus = this.googleCastSession.getMediaSession()

    this.sources = [
      {
        url: mediaStatus.media.contentUrl,
        contentType: mediaStatus.media.contentType
      }
    ]
    this.sourceIndex = 0

    this.continuePlayer()

    this.showOverlayMessage(translation('onspace.media.player.playback.media_remote'), { icon: 'onspace/player_google_cast' })
  }

  /// Stops the current Google Cast media playback.
  destroyGoogleCastPlayer() {}

  ///// Events

  /// Responds to a change in the Google Cast connection state.
  googleCastConnectedChanged(_event) {
    if (this.playbackStarted) {
      this.restartPlayer({ resume: true })
    }

    this.triggerEvent('onspace:media:player:remote-changed')
  }

  /// Responds to a change in the Google Cast player's loaded media.
  googleCastMediaLoadedChanged(_event) {
    if (!this.playbackStarted && this.googleCastActive) {
      this.continuePlayerGoogleCast()
    } else if (!this.googleCastPlayer.isMediaLoaded && this.playingAdvertisement) {
      this.advertisementCompleted()
    }

    this.triggerEvent('onspace:media:player:remote-changed')
    this.triggerEvent('onspace:media:player:playback-changed')
    this.triggerEvent('onspace:media:player:position-changed')
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  /// Responds to the Google Cast player pausing or resuming.
  googleCastPausedChanged(_event) {
    if (this.googleCastActive) {
      this.googleCastLastIsPlaying = this.isPlaying
    }

    this.triggerEvent('onspace:media:player:playback-changed')
  }

  /// Responds to the Google Cast player updating its time position.
  googleCastCurrentTimeChanged(_event) {
    if (this.googleCastActive) {
      this.googleCastLastPosition = this.position
    }

    this.triggerEvent('onspace:media:player:position-changed')
  }

  /// Responds to a change in the Google Cast player's ability to control volume.
  googleCastCanControlVolumeChanged(_event) {
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  /// Responds to a change in the Google Cast player's muted state.
  googleCastVolumeMutedChanged(_event) {
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  /// Responds to a change in the Google Cast player's volume level.
  googleCastCurrentVolumeChanged(_event) {
    this.triggerEvent('onspace:media:player:volume-changed')
  }

  ////////// Activation

  /// Activates the current player for playback.
  ///
  /// This marks the player as active and enables audio and controls. Unless +resume+ is false, playback is resumed.
  activate({ resume = true } = {}) {
    if (this.activated) { return }

    this.activated = true
    this.enableAudio()
    if (resume) {
      this.resumePlayback()
    }

    this.clearMessages()
    this.updateMediaMetadata()

    this.classList.remove('onspace-player--deactivated')
    this.classList.remove('onspace-player--deactivated-audio')
    this.triggerEvent('onspace:media:player:activated-changed')
  }

  /// Deactivates the current player for playback.
  ///
  /// This marks the player as active, disables the audio and controls, and pauses playback.
  deactivate({ pause = true, requiresAudio = false } = {}) {
    if (!this.activated) { return }

    this.activated = false
    this.disableAudio()
    if (pause) {
      this.pausePlayback()
    }

    this.classList.add('onspace-player--deactivated')
    if (requiresAudio) {
      this.classList.add('onspace-player--deactivated-audio')
    }

    this.triggerEvent('onspace:media:player:activated-changed')
  }

  ////////// Metadata

  /// Retrieves the metadata object.
  get metadata() {
    return this._metadata
  }

  /// Updates the metadata object.
  ///
  /// This triggers events to update the metadata as necessary.
  set metadata(value) {
    this._metadata = value

    this.updateMediaMetadata()
    this.triggerEvent('onspace:media:player:metadata-changed')
  }

  /// Updates the metadata for the device's media session.
  updateMediaMetadata() {
    if (navigator.mediaSession && typeof navigator.mediaSession.metadata !== 'object') { return }

    const options = {}
    if (this.metadata.title) { options.title = this.metadata.title }
    if (this.metadata.subtitle) { options.album = this.metadata.subtitle }
    if (this.metadata.artwork) { options.artwork = [{ src: this.metadata.artwork }] }

    navigator.mediaSession.metadata = new MediaMetadata(options)
  }

  ////////// Loading

  /// Determine if the player is loading and update the state to reflect.
  detectLoading() {
    switch (this.nativePlayer.readyState) {
    case HTMLMediaElement.HAVE_NOTHING:
    case HTMLMediaElement.HAVE_METADATA:
      this.isLoading = true
      break
    case HTMLMediaElement.HAVE_CURRENT_DATA:
    case HTMLMediaElement.HAVE_FUTURE_DATA:
    case HTMLMediaElement.HAVE_ENOUGH_DATA:
    default:
      this.isLoading = false
      break
    }
  }

  /// Indicates if the player is currently loading.
  get isLoading() {
    return this._isLoading
  }

  /// Updates the loading state of the player.
  set isLoading(loading) {
    this._isLoading = loading

    if (loading) {
      this.classList.add('onspace-player--loading')
    } else {
      this.classList.remove('onspace-player--loading')
    }

    this.triggerEvent('onspace:media:player:loading-changed')
  }

  ////////// Playback

  /// Determines if the player is currently playing media content.
  get isPlaying() {
    if (!this.playbackStarted) { return false }

    if (this.googleCastActive) {
      return !this.googleCastPlayer.isPaused
    } else if (!this.nativePlayer) {
      return false
    } else {
      return !this.nativePlayer.paused
    }
  }

  /// Resumes or pauses the playback, depending on the current state.
  togglePlayback() {
    if (this.googleCastActive) {
      this.googleCastController.playOrPause()
    } else if (this.isPlaying) {
      this.pausePlayback()
    } else {
      this.resumePlayback()
    }
  }

  /// Resumes playing media content, if it is not already playing.
  ///
  /// Modern browsers will prevent sites from automatically playing audio content without user interaction. If the
  /// browser prevents playback, this will check +requiresAudio+ to determine how to proceed. When true, playback will
  /// be paused, when false playback will continue silently. When this occurs, the player should listen for user
  /// interaction, then call +activate+.
  resumePlayback() {
    if (this.googleCastActive) {
      if (!this.isPlaying) {
        this.googleCastController.playOrPause()
      }
      return
    }

    if (!this.nativePlayer || !this.nativePlayer.paused) { return }

    const promise = this.nativePlayer.play()
    if (promise) {
      promise.catch((_error) => {
        this.endRepeatedlyAdjustingPosition()

        if (this.requiresAudio) {
          this.deactivate({ requiresAudio: true })
          this.showOverlayMessage(translation('onspace.media.player.playback.click_to_play'), { icon: 'onspace/player_play' })
        } else {
          this.deactivate()
          this.nativePlayer.play()
            .then(() => this.showOverlayMessage(translation('onspace.media.player.playback.click_to_activate')))
            .catch((_error) => {
              if (this.isPlaying) {
                this.showOverlayMessage(translation('onspace.media.player.playback.click_to_activate'))
              } else {
                this.showOverlayMessage(translation('onspace.media.player.playback.click_to_play'), { icon: 'onspace/player_play' })
              }
            })
        }
      })
    }
  }

  /// Pauses playing media content, if it is not already playing.
  pausePlayback() {
    if (this.googleCastActive) {
      if (this.isPlaying) {
        this.googleCastController.playOrPause()
      }
      return
    }

    if (!this.nativePlayer || this.nativePlayer.paused) { return }

    this.nativePlayer.pause()
  }

  ////////// Timeline

  /// Informs the player that a seek is about to begin.
  ///
  /// This pauses playback and all calls to set the +position+ are cached. When +endSeek+ is called, playback resumes if
  /// it was playing previously, and the cached position is applied.
  beginSeek() {
    this.seekWasPlaying = this.isPlaying
    this.seekPosition = this.position
    this.isSeekingPosition = true

    this.pausePlayback()
  }

  /// Informs the player that a seek has ended.
  endSeek() {
    this.isSeekingPosition = false
    this.position = this.seekPosition

    if (this.seekWasPlaying) {
      this.resumePlayback()
    }
  }

  /// Retrieves the playback position from the start of the media in seconds.
  get position() {
    if (this.isSeekingPosition) {
      return this.seekPosition
    } else if (this.googleCastActive) {
      return this.googleCastPlayer.currentTime
    } else if (!this.nativePlayer) {
      return 0
    } else {
      return this.nativePlayer.currentTime
    }
  }

  /// Sets the playback position of the media.
  set position(time) {
    if (this.isSeekingPosition) {
      if (time < 0) {
        time = 0
      } else if (time > this.duration) {
        time = this.duration
      }

      this.seekPosition = time
      this.triggerEvent('onspace:media:player:position-changed')
    } else if (this.googleCastActive) {
      this.googleCastPlayer.currentTime = time
      this.googleCastController.seek()

      this.triggerEvent('onspace:media:player:position-changed')
    } else if (this.nativePlayer) {
      this.nativePlayer.currentTime = time
    }
  }

  /// Adjusts the playback position by the given duration in seconds.
  ///
  /// This adds the +time+ value to the current position. A negative value will move the position backwards.
  adjustPosition(time) {
    if (this.isLiveContent) { return }

    this.position += time
  }

  /// Repeatedly adjusts the playback by the given duration after every interval period.
  ///
  /// This will pause playback if necessary, then setup a repeating interval which calls +adjustPosition+ with the given
  /// time. This will not stop until +endRepeatedlyAdjustingPosition+ is called.
  beginRepeatedlyAdjustingPosition(time, interval) {
    if (this.isLiveContent) { return }

    if (this.repeatedlyAdjustingPositionInterval) {
      clearInterval(this.repeatedlyAdjustingPositionInterval)
    } else {
      this.beginSeek()
    }

    this.adjustPosition(time)
    this.repeatedlyAdjustingPositionInterval = setInterval(() => this.adjustPosition(time), interval)
  }

  /// End repeatedly adjusting the playback position.
  ///
  /// This will also resume playback unless the player was paused when starting the repeated adjustment.
  endRepeatedlyAdjustingPosition() {
    clearInterval(this.repeatedlyAdjustingPositionInterval)
    this.repeatedlyAdjustingPositionInterval = null

    this.endSeek()
  }

  /// Retrieves the length of the media in seconds.
  get duration() {
    if (this.googleCastActive) {
      return this.googleCastPlayer.duration
    } else if (!this.nativePlayer) {
      return 0
    } else {
      return this.nativePlayer.duration
    }
  }

  /// Determines if the currently playing content is live.
  get isLiveContent() {
    return this.duration === Infinity
  }

  /// Determines if the media has completed playing.
  get hasEnded() {
    return this.position >= this.duration || this.position < 0
  }

  ///// Chapters

  /// Initialises the player with chapter metadata, if provided.
  setupChapters() {
    if (!this.metadata || !Array.isArray(this.metadata.chapters) || this.metadata.chapters.length == 0 || this.playingAdvertisement) {
      delete this.chapters
      return
    }

    const chapters = []
    this.metadata.chapters.forEach((chapter) => {
      let type = chapter.type || null
      if (!!type && !CHAPTER_TYPES.includes(type)) {
        console.warn(`Invalid chapter type ${type}`) // eslint-disable-line no-console
        type = null
      }

      let startTime = chapter.startTime || chapter.start_time

      if (chapters.length === 0) {
        if (!!startTime || startTime !== 0) {
          console.warn('The first chapter must have a startTime of 0') // eslint-disable-line no-console
        }

        startTime = 0
      } else if (!startTime) {
        console.warn('Chapters must include a startTime') // eslint-disable-line no-console
        return
      } else {
        const lastChapter = chapters[chapters.length - 1]
        if (startTime <= lastChapter.startTime) {
          console.warn('Chapters must have a startTime greater than the previous chapter') // eslint-disable-line no-console
          return
        }
      }

      chapters.push({
        title: chapter.title,
        startTime: startTime,
        type: type,
        canSkip: type !== null
      })
    })

    if (chapters.length < 2) {
      console.warn('Metadata chapters must contain at least 2 items') // eslint-disable-line no-console
      delete this.chapters
      return
    }

    chapters[chapters.length - 1].canSkip = false

    this.chapters = chapters
    this.startChapter(0)
  }

  /// Indicates the chapter currently setup.
  get currentChapter() {
    if (!this.chapters) { return null }

    return this.chapters[this.chapterIndex]
  }

  /// Detects the current chapter based on the playback position, and starts it if necessary.
  detectChapter() {
    if (!this.chapters) { return }

    const currentPosition = this.position

    let index = 0
    let nextChapter = this.chapters[index + 1]
    while (nextChapter && nextChapter.startTime < currentPosition) {
      index += 1
      nextChapter = this.chapters[index + 1]
    }

    if (this.chapterIndex !== index) {
      this.startChapter(index)
    }
  }

  /// Continues playback for the chapter at the given index.
  ///
  /// This configures the UI for the given chapter's type.
  startChapter(index) {
    this.chapterIndex = index

    const chapter = this.currentChapter
    if (chapter.type === CHAPTER_TYPE_CREDITS && !chapter.canSkip) {
      this.showUpNext()
    }

    this.triggerEvent('onspace:media:player:chapter-changed')
  }

  /// Indicates if the current chapter can be skipped.
  get canSkipChapter() {
    if (!this.chapters) { return false }

    return this.currentChapter.canSkip
  }

  /// Skips to the end of the current chapter, if it is skippable.
  skipChapter() {
    if (!this.currentChapter.canSkip) { return }

    const nextChapter = this.chapters[this.chapterIndex + 1]
    this.position = nextChapter.startTime
  }

  ////////// Audio

  /// Indicates if the player requires the audio to be played.
  ///
  /// The default implementation returns +true+ when playing an advertisement. Override this in a subclass to customise
  /// this.
  get requiresAudio() {
    return this.playingAdvertisement
  }

  /// Determines if audio is enabled on the player.
  get audioEnabled() {
    if (this.googleCastActive) {
      return !this.googleCastPlayer.isMuted
    } else if (!this.nativePlayer) {
      return false
    } else {
      return !this.nativePlayer.muted && this.nativePlayer.volume > 0
    }
  }

  /// Indicates if the player is allowed to control it's own audio.
  get canControlAudio() {
    if (this.googleCastActive) {
      return this.googleCastPlayer.canControlVolume
    } else {
      return this.activated
    }
  }

  /// Retrieves the current volume level of the player.
  get audioVolume() {
    if (this.googleCastActive) {
      return this.googleCastPlayer.volumeLevel
    } else if (!this.nativePlayer) {
      return 0
    } else {
      return this.nativePlayer.volume
    }
  }

  /// Sets the current volume level of the player.
  set audioVolume(volume) {
    if (this.googleCastActive) {
      this.googleCastPlayer.volumeLevel = volume
      this.googleCastController.setVolumeLevel()
      return
    }

    if (!this.nativePlayer) { return }

    volume = Math.min(Math.max(volume, 0), 1)

    if (volume === 0) {
      this._disabledAudioVolume = this.nativePlayer.volume
      this.nativePlayer.muted = true
    } else {
      this.nativePlayer.muted = false
    }

    this.nativePlayer.volume = volume
  }

  /// Adjusts the audio volume by the given amount.
  ///
  /// This adds the +amount+ value to the current volume. A negative value will turn the volume down.
  adjustAudioVolume(amount) {
    this.audioVolume += amount
  }

  /// Repeatedly adjusts the audio volume by the given amount after every interval period.
  ///
  /// This will setup a repeating interval which calls +adjustAudioVolume+ with the given amount. This will not stop
  /// until +endRepeatedlyAdjustingAudioVolume+ is called.
  beginRepeatedlyAdjustingAudioVolume(amount, interval) {
    if (this.repeatedlyAdjustingAudioVolumeInterval) {
      clearInterval(this.repeatedlyAdjustingAudioVolumeInterval)
    }

    this.adjustAudioVolume(amount)
    this.repeatedlyAdjustingAudioVolumeInterval = setInterval(() => this.adjustAudioVolume(amount), interval)
  }

  /// End repeatedly adjusting the audio volume.
  endRepeatedlyAdjustingAudioVolume() {
    clearInterval(this.repeatedlyAdjustingAudioVolumeInterval)
    this.repeatedlyAdjustingAudioVolumeInterval = null
  }

  /// Enables or disables audio, depending on the current state.
  toggleAudio() {
    if (this.audioEnabled) {
      this.disableAudio()
    } else {
      this.enableAudio()
    }
  }

  /// Enables audio on the player.
  enableAudio() {
    if (this.googleCastActive) {
      if (!this.audioEnabled) {
        this.googleCastController.muteOrUnmute()
      }
      return
    }

    this.audioVolume = this._disabledAudioVolume || 1
  }

  /// Disables audio on the player.
  disableAudio() {
    if (this.googleCastActive) {
      if (this.audioEnabled) {
        this.googleCastController.muteOrUnmute()
      }
      return
    }

    this.audioVolume = 0
  }

  ////////// Controls

  /// Initialises and configures the controls element.
  setupControls() {
    if (this.controlsElement) { return }

    this.controlsElement = this.createControlsElement()
    this.contentElement.appendChild(this.controlsElement)
  }

  /// Creates a controls element.
  ///
  /// The default implementation of this raises an error, this should be overridden in a subclass.
  createControlsElement() {
    throw 'This method must be implemented in a subclass'
  }

  /// Remove the controls element from the DOM and clears the reference.
  destroyControls() {
    if (!this.controlsElement) { return }

    this.controlsElement.remove()
    this.controlsElement = null
  }

  ////////// Messages

  /// Removes any messages that are currently displayed.
  clearMessages() {
    if (this.overlayMessage) {
      this.overlayMessage.remove()
      this.overlayMessage = null
    }

    if (this.coverMessage) {
      this.coverMessage.remove()
      this.coverMessage = null
    }

    this.contentElement.style.display = ''
  }

  /// Displays a messages over the existing player.
  ///
  /// This should be used to display information to a user. This accepts the following options:
  /// [icon]
  ///   An optional icon to be shown with the message.
  showOverlayMessage(message, options={}) {
    this.clearMessages()

    this.overlayMessage = document.createElement('div')
    this.overlayMessage.classList.add('onspace-player__overlay-message')

    if (options.icon) {
      const iconElement = SVGElement.createOnspaceSpritemapSvg(options.icon)
      this.overlayMessage.appendChild(iconElement)
    }

    const messageElement = document.createElement('p')
    messageElement.innerText = message
    this.overlayMessage.appendChild(messageElement)

    this.contentElement.appendChild(this.overlayMessage)
  }

  /// Displays a message that covers the entire player.
  ///
  /// This should be used to display information relating to an error which caused the player to stop completely. Prior,
  /// to calling this, any active player and other elements should be cleaned up. This accepts the following options:
  /// [icon]
  ///   An optional icon to be shown with the message. By default, this will be set to +onspace/icon_warning+.
  /// [actions]
  ///   An optional array of actions to be shown with the message. This should be an array of objects, each containing a
  ///   +title+ and +callback+.
  ///
  /// Note that the cover message is always appended to +this+, and not the +contentElement+.
  showCoverMessage(message, options={}) {
    this.clearMessages()

    this.coverMessage = document.createElement('div')
    this.coverMessage.classList.add('onspace-player-cover-message')

    const icon = options.icon || 'onspace/icon_warning'
    const iconElement = SVGElement.createOnspaceSpritemapSvg(icon)
    this.coverMessage.appendChild(iconElement)

    const messageElement = document.createElement('p')
    messageElement.innerText = message
    this.coverMessage.appendChild(messageElement)

    if (options.actions && options.actions.length > 0) {
      const actionsElement = document.createElement('div')
      actionsElement.classList.add('onspace-player-cover-message__actions')

      options.actions.forEach((action) => {
        const actionElement = document.createElement('div')
        actionElement.classList.add('onspace-button')
        actionElement.innerText = action.title
        actionElement.addEventListener('click', action.callback)

        actionsElement.appendChild(actionElement)
      })

      this.coverMessage.appendChild(actionsElement)
    }

    if (this.closeButtonCallback) {
      const closeButton = document.createElement('a')
      closeButton.classList.add('onspace-player-cover-message__close')
      closeButton.addEventListener('click', this.closeButtonCallback)

      const svgElement = SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross')
      closeButton.appendChild(svgElement)

      this.coverMessage.appendChild(closeButton)
    }

    this.prepend(this.coverMessage)

    this.contentElement.style.display = 'none'
  }

  ////////// Advertising

  /// Configures the current player to be showing advertisements.
  ///
  /// Once enabled, the player will behave differently to normal:
  /// - Playback will not proceed without audio.
  /// - Controls will be disabled and hidden.
  /// - When playback completes, the player will restart, reloading the sources using +sourcesLoader+ if provided.
  ///
  /// Pass a single object argument, which will be sent to the OnspacePlayerAdvertisement's constructor.
  configureAdvertisement(advertisement={}) {
    this.playingAdvertisement = true

    this.destroyControls()
    this.setupAdvertisementElement(advertisement)
  }

  /// Removes advertising from the current player.
  ///
  /// This reverts everything enabled in +configureAdvertisement+.
  clearAdvertisement() {
    this.playingAdvertisement = false

    this.destroyAdvertisementElement()
  }

  /// Called when an advertisement has completed.
  ///
  /// This will be called when either:
  /// - The current advertisement reaches it's end.
  /// - The "skip" button is used.
  advertisementCompleted() {
    if (!this.playingAdvertisement) { return }

    this.playedAdvertisement = true
    this.clearAdvertisement()
    this.restartPlayer()
  }

  /// Initialises and configures the advertisement element.
  setupAdvertisementElement(advertisement) {
    if (this.advertisementElement) { return }

    this.advertisementElement = this.createAdvertisementElement(advertisement)
    this.contentElement.appendChild(this.advertisementElement)
  }

  /// Create an advertisement element.
  ///
  /// This can be overridden in a subclass to create a different element.
  createAdvertisementElement(advertisement) {
    return new OnspacePlayerAdvertisement({ player: this, ...advertisement })
  }

  /// Remove the advertisement element from the DOM and clears the reference.
  destroyAdvertisementElement() {
    if (!this.advertisementElement) { return }

    this.advertisementElement.remove()
    this.advertisementElement = null
  }

  ////////// Tabs

  get systemTabData() {
    return [this._debugTab, this._upNextTab].filter((t) => t)
  }

  /// Retrieves the data for the current tabs.
  get tabData() {
    return [...this.systemTabData,  ...this._tabData]
  }

  /// Prepares the tab data for the player.
  setTabs(tabs) {
    this._tabData = tabs || []

    this.triggerEvent('onspace:media:player:tabs-changed')
  }

  ////////// Up Next

  /// Prepares the up next metadata for the player, and updates the tabs if required.
  ///
  /// This accepts the same parameters as +upNext+ in the constructor.
  setUpNext(options) {
    if (!options) {
      this.upNextData = null
      this._upNextTab = null
      this.triggerEvent('onspace:media:player:tabs-changed')

      return
    }

    const data = {
      metadata: options.metadata
    }

    if (typeof options.sources !== 'undefined') {
      data.sources = options.sources
    }

    if (options.automaticPlayInterval === false) {
      data.automaticPlayInterval = false
    } else {
      data.automaticPlayInterval = options.automaticPlayInterval || 10
    }

    this.upNextData = data
    if (data.metadata) {
      this._upNextTab = {
        title: translation('onspace.media.player.up_next.title'),
        type: 'showcase',
        metadata: data.metadata,
        actions: [
          { title: translation('onspace.media.player.up_next.play'), icon: 'onspace/player_play', callback: () => this.startNextPlayer() }
        ]
      }
    } else {
      this._upNextTab = null
    }
    this.triggerEvent('onspace:media:player:tabs-changed')
  }

  /// Indicates if the player is currently displaying the up next UI.
  get showingUpNext() {
    return !!this.upNextElement
  }

  /// Updates the UI to display the up next content.
  showUpNext() {
    if (!this.upNextData || this.upNextElement) { return }

    this.destroyControls()

    this.upNextElement = new OnspacePlayerUpNext({ player: this, metadata: this.upNextData.metadata, automaticPlayInterval: this.upNextData.automaticPlayInterval })
    this.contentElement.appendChild(this.upNextElement)
  }

  /// Removes the up next element and resets to the standard mode.
  removeUpNext() {
    if (!this.upNextElement) { return }

    this.upNextElement.remove()
    this.upNextElement = null

    this.setupControls()
  }

  /// Restarts the player with the up next content, if available.
  startNextPlayer() {
    this.removeUpNext()

    this.replaceMedia({ metadata: this.upNextData.metadata })
  }

  ////////// Remote

  /// Indicates if the player is currently using a remote device for playback.
  get remotePlaybackActive() {
    return this.airplayActive || this.googleCastActive
  }

  ///// Airplay

  /// Indicates if the media supports Airplay.
  get supportsAirplay() {
    return this.nativePlayer && typeof this.nativePlayer.webkitShowPlaybackTargetPicker === 'function'
  }

  /// Indicates if the player can currently use Airplay.
  get canAirplay() {
    return this.supportsAirplay && (!this.multiScreen || this.multiScreen.playerCanAirplay) && this.nativePlayerAirplayAvailable
  }

  /// Indicates if the player is currently in Airplay mode.
  get airplayActive() {
    return this.nativePlayer && this.nativePlayer.webkitCurrentPlaybackTargetIsWireless
  }

  /// Shows the UI to select an Airplay device.
  selectAirplayDevice() {
    this.nativePlayer.webkitShowPlaybackTargetPicker()
  }

  ///// Google Cast

  /// Indicates if the media supports Google Cast.
  get supportsGoogleCast() {
    return typeof window.cast !== 'undefined'
  }

  /// Indicates if the player can currently use Google Cast.
  get canGoogleCast() {
    return this.supportsGoogleCast && (!this.multiScreen || this.multiScreen.playerCanAirplay) //&& this.nativePlayerAirplayAvailable
  }

  /// Indicates if the player is connected to a Google Cast session.
  get googleCastConnected() {
    return this.supportsGoogleCast && this.googleCastSession !== null
  }

  /// Indicates if the player is actively playing media in a Google Cast session.
  get googleCastActive() {
    if (!this.googleCastConnected) { return false }

    const mediaStatus = this.googleCastSession.getMediaSession()
    if (!mediaStatus || !mediaStatus.media) { return false }

    return mediaStatus.media.contentId === this.googleCastMediaId
  }

  /// Shows the UI to select a Google Cast device.
  selectGoogleCastDevice() {
    window.cast.framework.CastContext.getInstance().requestSession()
  }

  ////////// Persistence

  /// Indicates if the player is currently persisted.
  get isPersisted() {
    return dialog.persistedPlayer === this
  }

  /// Starts persisting the player with the persistence element.
  beginPersisting() {
    dialog.persistPlayer(this)
  }

  /// Ends persisting the player with the persistence element.
  async endPersisting(shouldReturn) {
    if (shouldReturn) {
      await dialog.returnToPlayer()
    }
    dialog.clearPersistedPlayer()
  }

  ////////// Analytics

  /// Configures analytics parameters.
  configureAnalytics() {
    if (!this.analyticsParams.player_type) {
      this.analyticsParams.player_type = 'inline'
    }
  }

  /// Reports an analytics event using Onspace's reporter, if available.
  ///
  /// This accepts the following arguments:
  /// [name]
  ///   The name of the event.
  /// [params]
  ///   The parameters for the event. This will be merged with +analyticsParams+.
  ///
  ///   The requirements for this differ depending on the event.
  reportAnalyticsEvent(name, params = {}) {
    if (!window.onspaceAnalyticsReporter) { return }

    const eventName = `onspace_media_player_${name}`
    const eventParams = { ...this.analyticsParams, ...params }

    window.onspaceAnalyticsReporter.reportEvent(eventName, eventParams)
  }

  ////////// Debugging

  /// Detects if debugging mode should be enabled automatically.
  ///
  /// When debugging mode is enabled or disabled, it's state is set in +sessionStorage+. This retrieves that state and
  /// will re-enable debugging mode if it had been for the session.
  detectDebugMode() {
    if (sessionStorage.getItem('onspace-media-player-debug')) {
      this.enableDebugMode()
    }
  }

  /// Toggles debugging mode on or off.
  toggleDebugMode() {
    if (this.debugMode) {
      this.disableDebugMode()
    } else {
      this.enableDebugMode()
    }
  }

  /// Enables debugging mode.
  ///
  /// As well as enabling debugging mode in the current player, it marks it as enabled for the session, so any new
  /// players will also have debug mode enabled.
  ///
  /// This also starts debugging mode, unless playback has not yet started.
  enableDebugMode() {
    if (this.debugMode) { return }

    this.debugMode = true
    sessionStorage.setItem('onspace-media-player-debug', true)

    this._debugTab = {
      title: 'Debug',
      type: 'custom',
      create: this.createDebugElement.bind(this)
    }
    this.triggerEvent('onspace:media:player:tabs-changed')
  }

  /// Disables debugging mode.
  ///
  /// As well as disabling debugging mode in the current player, it marks it as disabled for the session, so any new
  /// players will also have debug mode disabled.
  disableDebugMode() {
    if (!this.debugMode) { return }

    this.debugMode = false
    sessionStorage.removeItem('onspace-media-player-debug')

    this._debugTab = null
    this.triggerEvent('onspace:media:player:tabs-changed')
  }

  /// Creates a debug element.
  ///
  /// The default implementation of this raises an error, this should be overridden in a subclass.
  createDebugElement() {
    throw 'This method must be implemented in a subclass'
  }
}
