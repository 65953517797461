import React from 'react'
import { createRoot } from 'react-dom/client'

const SVG_ELEMENTS = []

/// Registers an SVG component for use.
export function registerOnspaceSvg(name, element) {
  SVG_ELEMENTS[name] = element
}

/// Creates a new spritemap SVG element.
///
/// This is a replacement for the standard Onspace function to make the icons available within React contexts. This
/// utilises the JSX-compiled components as it doesn't have access to the spritemap.
///
/// To be available for use, the components must have been registered using +registerOnspaceSvg+. All built-in assets
/// will automatically register themselves when imported, but will not be imported automatically.
SVGElement.createOnspaceSpritemapSvg = function(name, _options) {
  const span = document.createElement('span')
  span.classList.add('onspace-svg')
  const root = createRoot(span)

  span.setOnspaceSpritemapSvg = function(innerName) {
    const Element = SVG_ELEMENTS[innerName]
    if (Element) {
      span.setAttribute('data-name', innerName)
      root.render(<Element _spritemap={true}></Element>)
    } else {
      span.removeAttribute('data-name')
      span.innerHTML = ''
    }
  }

  span.setOnspaceSpritemapSvg(name)

  return span
}
