import OnspaceDialog from '@onpace/onspace-core/elements/dialog'

/// An OnspaceDialog sub-element which presents media players.
///
/// Do not create new instances of this element, instead use the globally exported instance from this file. Media can be
/// played using the +launchPlayer+ method.
///
/// This element supports "persistent" functionality, that allows it to preserve playback through page loads. Using this
/// feature requires a framework that performs page loads through javascript instead of the normal browser mechanism.
/// Support for Hotwire/Turbo is built-in, but other frameworks can be used with the following callbacks:
/// - When a page load is completed, call +dialog.pageDidLoad()+.
/// - On first load, set the dialog's +returnToPlayerCallback+. The callback should return the browser to the location
///   containing the persisted player. This should be an async function, and will be called with an object containing
///   the +player+ and it's original +location+.
/// - When a new DOM tree will be loaded, but before it has been loaded onto the body, call +dialog.nodeWillRender()+.
///   This includes partial page loads. The new tree should be provided as the argument.
///
/// Persisted players must have a valid +id+ attribute, which must be unique across all pages of the site. It is used to
/// match a persisted player when loading new pages, and having multiple players with the same ID could cause unexpected
/// functionality.
class OnspacePlayerDialog extends OnspaceDialog {
  /// Runs when the dialog element is first connected to the DOM.
  runFirstConnected(options = {}) {
    options.showWhenConnected = false
    options.removeWhenClosed = false

    super.runFirstConnected(options)

    this.classList.add('onspace-player-dialog')
  }

  ////////// Dialog

  /// Gets the current dialog player element.
  get dialogPlayer() {
    return this._dialogPlayer
  }

  /// Indicates if there is a player in dialog mode.
  get playingInDialog() {
    return !!this.dialogPlayer
  }

  /// Creates and shows a player in the dialog.
  ///
  /// This takes two arguments:
  /// [type]
  ///   The type of player to create. Player classes must be pre-registered using +registerPlayerClass+.
  ///
  ///   If "clickout" is passed here, this will open the URL in a blank window.
  /// [playerOptions]
  ///   An object which is passed directly to the player's constructor. See OnspaceMediaPlayer for the required
  ///   arguments to pass here.
  ///
  /// This will return the newly created player instance.
  launchPlayer(type, playerOptions) {
    this.clearPersistedPlayer()
    this.removeDialogPlayer()

    if (type === 'clickout') {
      let url = null
      if (playerOptions.sources) {
        url = playerOptions.sources[0].url
      } else {
        url = playerOptions.src
      }

      window.open(url, '_blank')
      return
    }

    const playerClass = registeredPlayerClasses[type]
    if (!playerClass) { throw `No player class registered for type "${type}"` }

    playerOptions.autoplay = true
    playerOptions.closeButton = () => this.close()

    if (!playerOptions.analytics) { playerOptions.analytics = {} }
    if (!playerOptions.analytics.player_type) { playerOptions.analytics.player_type = 'dialog' }

    const player = new playerClass(playerOptions)
    player.id = 'onspace-player-dialog-player'
    this.contentElement.appendChild(player)

    this._dialogPlayer = player

    this.show()

    return player
  }

  /// Removes the current player from dialog mode.
  ///
  /// If there is no current player, this does nothing.
  removeDialogPlayer() {
    if (this.dialogPlayer) {
      this.dialogPlayer.remove()
    }

    this._dialogPlayer = null
  }

  ////////// Persisted

  /// Gets the current persisted player element.
  get persistedPlayer() {
    return this._persistedPlayer
  }

  /// Indicates if there is currently a player persisted.
  get playerPersisted() {
    return !!this.persistedPlayer
  }

  /// Sets the new value as the current player in persistent mode.
  persistPlayer(player) {
    if (player === this.persistedPlayer) { return }

    this.clearPersistedPlayer()

    if (player && player.id.length > 0) {
      this._persistedPlayer = player
      this.playerLocation = window.location.pathname

      this.attachPersistentPlayer()
    }
  }

  /// Removes the current player from persistent mode.
  ///
  /// If there is no current player, this does nothing.
  clearPersistedPlayer() {
    if (this.persistedPlayer) {
      this.returnNativePlayer()
    }

    this._persistedPlayer = null
    this.playerLocation = null
  }

  /// Attaches the current player's native player to this element.
  attachPersistentPlayer() {
    this.classList.add('onspace-player-dialog--persisted')

    const bounds = this.persistedPlayer.nativePlayer.getBoundingClientRect()
    this.style.top = `${bounds.top + window.scrollY}px`
    this.style.left = `${bounds.left + window.scrollY}px`
    this.style.width = `${bounds.width}px`
    this.style.height = `${bounds.height}px`

    setTimeout(() => {
      this.appendChild(this.persistedPlayer.nativePlayer)

      if (this.dialogPlayer) { this.close() }
    })
  }

  /// Returns the current player's native player to it.
  returnNativePlayer() {
    this.persistedPlayer.contentElement.prepend(this.persistedPlayer.nativePlayer)

    setTimeout(() => {
      this.classList.remove('onspace-player-dialog--persisted')
      this.removeAttribute('style')
    })
  }

  /// Returns the browser to the location containing the player.
  ///
  /// This uses the configured +returnToPlayerCallback+. If there is no current player, or the current player is already
  /// attached to the DOM, this does nothing.
  async returnToPlayer() {
    if (!this.persistedPlayer) { return }

    if (this.playingInDialog) {
      await this.show()
    } else {
      const bodyPlayer = document.querySelector(`#${this.persistedPlayer.id}`)
      if (bodyPlayer || typeof this.returnToPlayerCallback !== 'function') { return }

      await this.returnToPlayerCallback({ player: this.persistedPlayer, location: this.playerLocation })
    }
  }

  ////////// Callbacks

  /// Callback run when the dialog element is closed.
  ///
  /// This overrides OnspaceDialog.onClose to remove the active player.
  onClose() {
    super.onClose()

    if (!this.playerPersisted) {
      this.removeDialogPlayer()
    }
  }

  /// Callback run when the dialog is clicked.
  ///
  ///
  /// This overrides OnspaceDialog.dialogClicked to prevent the click handlers from running while a dialog player is in
  /// full screen mode.
  dialogClicked(event) {
    if (this.playingInDialog && this.dialogPlayer.fullscreenActive) { return }

    super.dialogClicked(event)
  }

  /// Callback for when a newly created node will be rendered into the page.
  ///
  /// If a player is currently persisted, it will be searched for within the new node. If it exists, the existing player
  /// will replace the found player element.
  nodeWillRender(node) {
    if (!this.persistedPlayer) { return }

    const element = node.querySelector(`#${this.persistedPlayer.id}`)
    if (element) {
      element.replaceWith(this.persistedPlayer)
    }
  }

  /// Callback for when a page is loaded.
  ///
  /// This appends itself to the body.
  pageDidLoad() {
    document.body.appendChild(this)
  }
}

window.customElements.define('onspace-player-dialog', OnspacePlayerDialog)

////////// Players

const registeredPlayerClasses = {}

/// Registers a class for use in the dialog.
///
/// The +klass+ argument must be the constructor object for an OnspaceMediaPlayer subclass.
export function registerDialogPlayerClass(type, klass) {
  registeredPlayerClasses[type] = klass
}

////////// Global

const dialog = new OnspacePlayerDialog()
export default dialog

document.addEventListener('turbo:before-render', (event) => {
  dialog.nodeWillRender(event.detail.newBody)
})

document.addEventListener('turbo:before-frame-render', (event) => {
  dialog.nodeWillRender(event.detail.newFrame)
})

let turboLoadCallback = null
document.addEventListener('turbo:load', (_event) => {
  if (!dialog.returnToPlayerCallback) {
    dialog.returnToPlayerCallback = ({ _player, location }) => {
      const promise = new Promise((resolve, _reject) => {
        window.Turbo.visit(location)

        turboLoadCallback = resolve
      })

      return promise
    }
  }

  dialog.pageDidLoad()
  if (turboLoadCallback) { turboLoadCallback() }
})
