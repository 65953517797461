import { openPlayerDialog as openPlatformPlayerDialog } from './player/dialog'

/// Parses the props given to a base media player component suitable for a concrete player.
///
/// The following attributes are supported:
/// [metadata]
///   Optional information describing the media. See OnspaceMediaPlayer for more details.
/// [upNext]
///   Provides the information for media which will play at the end of the current media. See OnspaceMediaPlayer for
///   more details.
/// [tabs]
///   Adds additional UI to the controls. These appear as tab buttons, with their content initially hidden. See
///   OnspaceMediaPlayer for more details.
///
///   When passing a +custom+ type tab, you can specify a React element by using the +component+ attribute instead of
///   the +create+ attribute.
/// [sources]
///   An array of source objects. Objects support the following attributes:
///   [url]
///     The URL or path to the source. This is required.
///   [contentType]
///     The mime type of the content contained in the source. This is optional, but each object should include one for
///     the most effective prioritisation.
///   [videoCodec]
///     Specifies the video codec of the source content. This will be ignored without a +contentType+.
///   [audioCodec]
///     Specifies the audio codec of the source content. This will be ignored without a +contentType+.
/// [sourceUrl]
///   The URL or path to the source. If provided, this overrides the +sources+ with a single object containing this url.
/// [sourcesLoader]
///   An asynchronous function which can load sources. See OnspaceMediaPlayer.loadSources for more information.
/// [options]
///   An object containing additional options:
///   [persistenceId]
///     An identifier for the player. This must be unique across the application, as it is used to match a persisted
///     player when loading new screens, and having multiple players with the same ID could cause unexpected results.
///
///     This is optional, but must be provided for picture-in-picture functionality to be available.
///   [autoplay]
///     Whether the video should start playing automatically. when set to false, this shows a placeholder overlayed with
///     a play button. The placeholder can be customised by providing +metadata+.
///
///     This is set to +false+ by default.
///
/// The following callbacks are also available:
/// [onMediaChanged]
///   Called when the player replaces the currently playing media item. This will _not_ be called when an advertisement
///   is completed. This will be called with the following arguments:
///   [mediaId]
///     The metadata id of the new media.
///
/// The following functions are exposed via the component's reference:
/// [replaceMedia()]
///   Stops the current player and reloads with new given media. See OnspaceMediaPlayer.replaceMedia.
///
/// At least one of +sources+ or +sourceUrl+ must be provided.
export function parsePlayerProps({ metadata, sources, sourceUrl, sourcesLoader, options, ...props }) {
  if (typeof sourcesLoader === 'function') {
    props.sourcesLoader = sourcesLoader
  } else if (typeof sourceUrl === 'string' && sourceUrl.length !== 0) {
    props.sources = [{ url: sourceUrl }]
  } else if (!Array.isArray(sources) || sources.length === 0) {
    throw new Error('MediaPlayer: `sources` or `sourceUrl` is a required attribute')
  } else {
    props.sources = sources
  }

  props.metadata = metadata || {}
  props.options = options || {}

  return props
}

/// Opens a media player in an overlay dialog.
///
/// Note that the method for doing this differs depending on the platform.
///
/// This takes two arguments:
/// [type]
///   The type of player to create. Player classes must be pre-registered with their respective platform dialogs.
/// [playerOptions]
///   An object which is passed directly to the player's constructor. See +parsePlayerProps+ for the required arguments
///   to pass here.
export function openPlayerDialog(type, props) {
  props = parsePlayerProps(props)

  return openPlatformPlayerDialog(type, props)
}
