import { createRoot } from 'react-dom/client'

import '@onpace/onspace-media/elements/player/iframe'
import '@onpace/onspace-media/elements/player/video'

import playerDialog from '@onpace/onspace-media/elements/player/dialog'

/// Opens a media player in an overlay dialog.
///
/// This returns an instance of the newly created player.
export function openPlayerDialog(type, playerOptions) {
  let onMediaChanged = null
  if (typeof playerOptions.onMediaChanged === 'function') {
    onMediaChanged = playerOptions.onMediaChanged
    delete playerOptions.onMediaChanged
  }

  if (Array.isArray(playerOptions.tabs)) {
    playerOptions.tabs.forEach((tab) => {
      if (tab.type === 'custom' && tab.component) {
        const div = document.createElement('div')
        div.classList.add('onspace-player-tabs__content__custom')

        const root = createRoot(div)
        root.render(tab.component)

        tab.content = div
      }
    })
  }

  const player = playerDialog.launchPlayer(type, playerOptions)

  if (onMediaChanged) {
    const onMediaChangedCallback = function(_event) {
      let mediaId = null
      if (player.metadata && typeof player.metadata.id === 'string') {
        mediaId = player.metadata.id
      }

      onMediaChanged(mediaId)
    }
    player.addEventListener('onspace:media:player:media-changed', onMediaChangedCallback.bind(this))
  }

  return player
}
