import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { parsePlayerProps } from '../../components/player'

/// The web sub-component for a video player.
///
/// The supported attributes are determined by +parsePlayerProps+.
///
/// This utilises Onspace Media's web-based video player. See OnspaceMediaPlayer and OnspaceVideoPlayer for more
/// details.
function VideoPlayerWeb(props, componentRef) {
  props = parsePlayerProps(props)

  const playerRef = useRef(null)

  useEffect(() => {
    if (playerRef.current !== null) {
      playerRef.current.updateMetadata(props.metadata)
    }
  }, [props.metadata])

  useEffect(() => {
    if (playerRef.current !== null) {
      playerRef.current.updateUpNext(props.upNext)
    }
  }, [props.upNext])

  useEffect(() => {
    if (playerRef.current !== null) {
      playerRef.current.updateTabs(props.tabs)
    }
  }, [props.tabs])

  useEffect(() => {
    playerRef.current.setupPlayer(props)
  }, [])

  useImperativeHandle(componentRef, () => {
    return {
      replaceMedia() {
        playerRef.current.replaceMedia(...arguments)
      }
    }
  })

  return <onspace-video-wrapper ref={playerRef} />
}

export default forwardRef(VideoPlayerWeb)
