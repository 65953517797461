const globalTranslations = {}

let globalLocale = window.navigator.language
const localeMeta = document.querySelector('meta[name=onspace-i18n-locale]')
if (localeMeta) {
  globalLocale = localeMeta.content
}

const fallbackLocale = 'en'

/// Registers translations.
///
/// This accepts an object, where the root keys correspond to
export function register(translations) {
  deepMerge(globalTranslations, translations)
}

/// Looks up a translation.
///
/// This looks for a translation key using dot syntax. This accepts the following arguments:
/// [key]
///   The translation key to lookup, in dot syntax.
/// [:locale]
///   The locale to use. If a regional locale is given (e.g. en-AU) it will fallback to the non-regional variant if
///   required.
export default function translation(key, { locale } = {}) {
  locale = locale || globalLocale

  let result = dig(globalTranslations[locale], key)
  if (result) { return result}

  if (locale.includes('-')) {
    const parentLocale = locale.split('-')[0]
    result = dig(globalTranslations[parentLocale], key)
    if (result) { return result}
  }

  return dig(globalTranslations[fallbackLocale], key)
}

/// Looks up an object key using dot syntax.
function dig(object, key) {
  if (typeof object !== 'object') { return null }

  if (key.includes('.')) {
    const [first, ...rest] = key.split('.')
    const subObject = object[first]
    return dig(subObject, rest.join('.'))
  } else {
    return object[key]
  }
}

/// Deeply merges an object into another.
function deepMerge(object, newObject) {
  Object.keys(newObject).forEach((key) => {
    const newObjectValue = newObject[key]
    if (Array.isArray(newObjectValue)) {
      if (Array.isArray(object[key])) {
        object[key] = object[key].concat(newObjectValue)
      } else {
        object[key] = newObjectValue
      }
    } else if (typeof newObjectValue === 'object') {
      if (typeof object[key] === 'object') {
        deepMerge(object[key], newObjectValue)
      } else {
        object[key] = newObjectValue
      }
    } else {
      object[key] = newObjectValue
    }
  })
}
