const FRAMEWORK_SCRIPT_SRC = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'

let isGoogleCastAvailable = false

function setupGoogleCast() {
  if (typeof window.navigator.presentation === 'undefined') { return }

  window['__onGCastApiAvailable'] = function(isAvailable) {
    if (!isAvailable) { return }

    isGoogleCastAvailable = true

    window.cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      resumeSavedSession: false
    })

    document.triggerEvent('onspace:google-cast:available')
  }

  const scriptElement = document.createElement('script')
  scriptElement.src = FRAMEWORK_SCRIPT_SRC
  document.head.appendChild(scriptElement)
}

setupGoogleCast()

export function onGoogleCastAvailable(callback) {
  if (isGoogleCastAvailable) {
    setTimeout(callback)
  } else {
    document.addEventListener('onspace:google-cast:available', callback)
  }
}
