import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import { isScrollingRetained } from '@onpace/onspace-core/components/scrollretain'
import translation from '@onpace/onspace-core/components/translations'

/// Responds to click events for dialog close links.
///
/// This closes the closest +onspace-dialog+ element.
async function dialogCloseClicked(event) {
  event.preventDefault()

  const dialog = event.target.closest('.onspace-dialog')
  await dialog.close()
}

////////// Dialog Stack

let shownDialogCount = 0

/// Increases the dialog stack count.
///
/// If the count was previously 0, this captures the scrolling of the page.
function dialogAddedToStack() {
  if (shownDialogCount == 0) {
    document.body.classList.add('onspace-dialog--active')
  }

  shownDialogCount += 1
}

/// Decreases the dialog stack count.
///
/// If the new value is 0, this releases the scrolling of the page, performing the opposite function to
/// +dialogAddedToStack+.
function dialogRemovedFromStack() {
  shownDialogCount -= 1

  if (shownDialogCount <= 1) {
    shownDialogCount = 0
    document.body.classList.remove('onspace-dialog--active')
  }
}

////////// Standard Dialog

/// An element which wraps the built in HTML Dialog element.
export default class OnspaceDialog extends CustomHTMLElement {
  /// Sets up the dialog element.
  ///
  /// This creates and appends a html +dialog+ element, and registers event listeners as necessary. The element supports
  /// the following options:
  /// [tooltip]
  ///   Displays the dialog in "tooltip" UI mode. This is set to +false+ by default.
  /// [showWhenConnected]
  ///   Indicates whether the dialog should automatically be shown when it is connected to the DOM. This is +true+ by
  ///   default.
  /// [removeWhenClosed]
  ///   Indicates whether the dialog should be removed from the DOM when it is hidden. This is +true+ by default.
  runFirstConnected(options = {}) {
    super.runConstructor()

    this.classList.add('onspace-dialog')

    this.dialogElement = document.createElement('dialog')
    this.dialogElement.addEventListener('cancel', this.onCancel.bind(this))
    this.dialogElement.addEventListener('close', this.onClose.bind(this))
    this.dialogElement.addEventListener('click', this.dialogClicked.bind(this), { capture: true })

    if (options.tooltip) {
      this.tooltip = true
      this.classList.add('onspace-dialog--tooltip')
    }

    this.showWhenConnected = options.showWhenConnected !== false
    this.removeWhenClosed = options.removeWhenClosed !== false

    this.appendChild(this.dialogElement)

    this.contentElement = document.createElement('div')
    this.contentElement.classList.add('onspace-dialog__content')
    this.dialogElement.appendChild(this.contentElement)

    if (typeof this.loadUrlOnConnected === 'string' && this.loadUrlOnConnected.length > 0) {
      this.loadUrl(this.loadUrlOnConnected)
      this.loadUrlOnConnected = null
    }
  }

  /// Callback which is run when the element is connected to the DOM.
  ///
  /// Automatically shows the dialog element.
  runConnected() {
    super.runConnected()

    if (this.showWhenConnected) {
      setTimeout(() => this.show())
    }
  }

  ////////// State

  /// Indicates if the dialog is currently open.
  get open() {
    return this.dialogElement.open
  }

  /// Shows the dialog modally.
  ///
  /// This returns a promise that resolves once the show animation completes.
  show() {
    return new Promise((resolve, _reject) => {
      if (this.open) {
        resolve()
        return
      }

      const dialogAnimationEnded = (_event) => {
        this.dialogElement.removeEventListener('animationend', dialogAnimationEnded)
        this.triggerEvent('onspace:dialog:show')

        dialogAddedToStack()

        resolve()
      }

      this.dialogElement.showModal()
      this.dialogElement.addEventListener('animationend', dialogAnimationEnded)
    })
  }

  /// Closes the dialog.
  ///
  /// This returns a promise that resolves once the close animation completes.
  close({ ignoreForms } = {}) {
    return new Promise((resolve, _reject) => {
      if (!this.open) {
        resolve()
        return
      }

      if (this.onspaceHasChangedForm && !ignoreForms) {
        const result = window.confirm(translation('onspace.navigation.form_unsaved.dialog'))
        if (!result) { return }
      }

      const dialogAnimationEnded = (_event) => {
        this.dialogElement.removeEventListener('animationend', dialogAnimationEnded)
        this.dialogElement.removeAttribute('close')
        this.dialogElement.close()
        this.triggerEvent('onspace:dialog:close')

        dialogRemovedFromStack()
        resolve()
      }

      this.dialogElement.setAttribute('close', '')
      this.dialogElement.addEventListener('animationend', dialogAnimationEnded)
    })
  }

  /// Callback run when the dialog is cancelled by the user.
  ///
  /// The cancel event is prevented, we manually call this elements +close+ function, so the animations still run.
  onCancel(event) {
    event.preventDefault()

    this.close()
  }

  /// Callback run when the dialog element is closed.
  ///
  /// This automatically removes this element from the DOM after the animations finish.
  onClose(_event) {
    if (this.removeWhenClosed) {
      this.remove()
    }
  }

  /// Callback run when the dialog is clicked.
  ///
  /// This detects when clicks are placed on the dialog's background, and if so closes the dialog.
  dialogClicked(event) {
    if (event.clientX == 0 && event.clientY == 0) { return }
    if (isScrollingRetained()) { return }

    const rect = this.contentElement.getBoundingClientRect()
    if (event.clientY < rect.top || rect.bottom < event.clientY || event.clientX < rect.left || rect.right < event.clientX) {
      event.preventDefault()
      event.stopPropagation()
      this.close()
    }
  }

  ////////// Frame

  /// Creates a +turbo-frame+ element, and adds it to the dialog.
  createTurboFrame() {
    if (this.turboFrame) { return }

    this.turboFrame = document.createElement('turbo-frame')
    this.turboFrame.id = `dialog-${Math.random().toString(36).substr(2)}`

    this.turboFrame.addEventListener('turbo:frame-load', this.turboFrameLoaded.bind(this))

    this.contentElement.appendChild(this.turboFrame)
  }

  /// Loads a URL into the turbo frame.
  ///
  /// This also creates the turbo frame if necessary.
  loadUrl(url) {
    if (!this.dialogElement) {
      this.loadUrlOnConnected = url
      return
    }

    this.createTurboFrame()
    this.turboFrame.innerHTML = ''
    this.turboFrame.src = url

    const loadingElement = document.createElement('div')
    loadingElement.classList.add('onspace-dialog__loading')

    const icon = SVGElement.createOnspaceSpritemapSvg('onspace/logo_onspace_loading')
    loadingElement.appendChild(icon)

    this.turboFrame.appendChild(loadingElement)
  }

  /// Callback run when the turbo frame loads.
  ///
  /// This simply calls +processDialogContent+.
  turboFrameLoaded(_event) {
    this.processDialogContent()
    setTimeout(() => this.dialogElement.scrollTop = 0)
  }

  ////////// Content

  /// Processes content in the dialog to modify and add event listeners.
  ///
  /// This does the following:
  /// - If there is no close button in the dialog, runs +createCloseButton+.
  /// - If there is a form, focus the submit button.
  processDialogContent() {
    const closeElements = this.querySelectorAll('[data-turbo-action=dialog-close]')
    closeElements.forEach((element) => element.addEventListener('click', dialogCloseClicked))

    if (closeElements.length === 0) {
      this.createCloseButton()
    }

    const form = this.querySelector('form')
    if (form) {
      const formButton = this.querySelector('button[type=submit]')
      if (formButton) {
        formButton.focus()
      }
    }
  }

  /// Creates a close button and adds it to the heading element.
  createCloseButton() {
    let headingElement = this.querySelector('.onspace-heading')
    if (!headingElement) {
      if (this.tooltip) {
        const buttonAnchor = document.createElement('a')
        buttonAnchor.classList.add('onspace-dialog--tooltip__close')
        buttonAnchor.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross'))
        buttonAnchor.addEventListener('click', dialogCloseClicked)

        if (this.turboFrame) {
          this.turboFrame.prepend(buttonAnchor)
        } else {
          this.contentElement.prepend(buttonAnchor)
        }
        return
      }

      headingElement = document.createElement('div')
      headingElement.classList.add('onspace-heading')
      headingElement.appendChild(document.createElement('h1'))

      if (this.turboFrame) {
        this.turboFrame.prepend(headingElement)
      } else {
        this.contentElement.prepend(headingElement)
      }
    }

    const buttonAnchor = document.createElement('a')
    buttonAnchor.classList.add('onspace-heading__dialog-close')
    buttonAnchor.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross'))
    buttonAnchor.addEventListener('click', dialogCloseClicked)

    const buttonBar = headingElement.querySelector('.onspace-button-bar')
    if (buttonBar) {
      headingElement.insertBefore(buttonAnchor, buttonBar)
    } else {
      headingElement.appendChild(buttonAnchor)
    }
  }
}

////////// Selection dialog

/// A dialog for use with the CMS controller +select+ action.
///
/// This inherits from OnspaceDialog, adding some additional functionality specific to the +select+ action:
/// - Listens for changes to the selected values, forwards those changes via Javascript events.
/// - Accepts a set of initial values which are used to apply preselection when the dialog loads.
export class OnspaceDialogSelect extends OnspaceDialog {
  /// Sets up the dialog element.
  ///
  /// This overrides OnspaceDialog.runConstructor to create a dialog with additional functionality for selecting
  /// associations. This accepts the following additional parameters:
  /// [selectedValues]
  ///   An array of values which are pre-selected. These will be applied automatically to the loaded response.
  runConstructor(options = {}) {
    super.runConstructor(options)

    this.selectedValues = options.selectedValues || []
  }

  /// Processes content in the dialog to modify and add event listeners.
  ///
  /// This overrides OnspaceDialog.processDialogContent to additionally do the following:
  /// - Find and process each selection item, using +prepareSelectionItem+ for each.
  processDialogContent() {
    super.processDialogContent()

    const selectionItems = this.querySelectorAll('[data-selection-id],[data-selection-url]')
    Array.from(selectionItems).forEach(item => this.prepareSelectionItem(item))
  }

  ////////// Frame

  /// Creates a +turbo-frame+ element, and adds it to the dialog.
  ///
  /// This overrides OnspaceDialog to capture requests that occur after a new model is created.
  createTurboFrame() {
    super.createTurboFrame()

    this.turboFrame.addEventListener('turbo:submit-end', this.turboFrameSubmissionEnded.bind(this))
  }

  /// Callback run when form submission ends in the turbo frame.
  ///
  /// This checks for JSON responses which contain models for selection.
  async turboFrameSubmissionEnded(event) {
    const response = event.detail.fetchResponse
    if (response.succeeded && response.contentType && response.contentType.match(/^(application|text)\/json(;|$)/)) {
      const data = await response.response.json()
      const selectionData = {
        value: data.id,
        title: data.title,
        subtitle: data.subtitle,
        imageUrl: data.imageUrl,
        content: data.content
      }

      this.triggerEvent('onspace:dialog:selected', selectionData)
      this.close({ ignoreForms: true })
    }
  }

  ////////// Items

  /// Prepares a selection item for use.
  ///
  /// This does the following:
  /// - Retrieves the item's data attributes.
  /// - Locates the checkbox or radio button and listens for change events.
  /// - Adds a click event on the item which toggles the input.
  prepareSelectionItem(item) {
    item.url = item.getAttribute('data-selection-url')
    item.value = item.getAttribute('data-selection-id')
    item.title = item.getAttribute('data-selection-title')
    item.subtitle = item.getAttribute('data-selection-subtitle')
    item.imageUrl = item.getAttribute('data-selection-imageurl')
    item.content = item.getAttribute('data-selection-content')
    item.input = item.querySelector('input')

    item.selectionData = { value: item.value, title: item.title, subtitle: item.subtitle, imageUrl: item.imageUrl, content: item.content }

    if (this.selectedValues.includes(item.value)) {
      item.input.checked = true
    }

    item.input.addEventListener('change', (_event) => {
      this.itemSelected(item)
    })

    if (item.tagName !== 'LABEL') {
      item.addEventListener('click', (event) => {
        if (event.target == item.input) { return }

        if (item.input.type === 'checkbox') {
          item.input.checked = !item.input.checked
        } else {
          if (item.input.checked) { return }

          item.input.checked = true
        }

        if (item.url) {
          this.loadUrl(item.url)
        } else {
          this.itemSelected(item)
        }
      })
    }
  }

  /// Responds to changes in item selection.
  itemSelected(item) {
    if (item.input.checked) {
      this.selectedValues.push(item.value)
      this.triggerEvent('onspace:dialog:selected', item.selectionData)
    } else {
      const selectedIndex = this.selectedValues.indexOf(item.value)
      if (selectedIndex !== -1) { this.selectedValues.splice(selectedIndex, 1) }

      this.triggerEvent('onspace:dialog:deselected', item.selectionData)
    }

    if (item.input.type === 'radio') {
      setTimeout(() => this.close(), 200)
    }
  }
}

//////////

window.customElements.define('onspace-dialog', OnspaceDialog)
window.customElements.define('onspace-dialog-select', OnspaceDialogSelect)
