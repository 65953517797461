import OnspacePlayerControls from '@onpace/onspace-media/elements/player/controls'

/// The Onspace Player Controls is an element to control a Video Player.
export default class OnspaceVideoPlayerControls extends OnspacePlayerControls {
  /// Runs when the controls element is first connected to the DOM.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.addEventListener('mousemove', this.mouseMoved.bind(this))
    this.addEventListener('mouseout', this.mouseLeft.bind(this))

    this.player.addEventListener('onspace:media:player:video-presentation-changed', this.playerPresentationChanged.bind(this))
    if (this.player.multiScreen) {
      this.player.multiScreen.addEventListener('onspace:media:multi-screen:players-changed', this.multiScreenPlayersChanged.bind(this))
    }

    this.becomeVisible()
  }

  ////////// Events

  ///// Interaction

  /// Responds to mouse movement over the element.
  ///
  /// This will make the element visible.
  mouseMoved(_event) {
    this.becomeVisible()
  }

  /// Responds to the mouse leaving the element.
  ///
  /// This will make the element invisible.
  mouseLeft(_event) {
    this.becomeInvisible()
  }

  ///// Player

  /// Responds to the player changing is activated state.
  playerActivatedChanged(event) {
    super.playerActivatedChanged(event)

    if (this.player.activated) {
      this.becomeVisible()
    } else {
      this.becomeInvisible()
    }
  }

  /// Responds to the playback state of the player changing.
  playerPlaybackChanged(event) {
    super.playerPlaybackChanged(event)

    this.becomeVisible()
  }

  /// Responds to the presentation state of the player changing.
  playerPresentationChanged(_event) {
    this.updateMultiScreenControls()
    this.updatePresentationControls()
  }

  ///// Multi-Screen

  /// Responds to changes in the multi-screen's player configuration.
  multiScreenPlayersChanged(_event) {
    this.updateMultiScreenControls()
  }

  ////////// Visibility

  /// Determines if the element can be hidden.
  get canBeInvisible() {
    return this.player.isPlaying && !this.player.remotePlaybackActive && !this.tabsElement.expanded
  }

  /// Indicates if the element is currently visible.
  get isVisible() {
    return this.classList.contains('onspace-player__controls--visible')
  }

  /// Makes the element visible or invisible, depending on the current state.
  toggleVisibility() {
    if (this.isVisible) {
      this.becomeInvisible()
    } else {
      this.becomeVisible()
    }
  }

  /// Makes the element visible.
  ///
  /// Unless the element can't be hidden, this will automatically hide the element again after a short period.
  becomeVisible() {
    if (this.visibilityTimeout) {
      window.clearTimeout(this.visibilityTimeout)
      this.visibilityTimeout = null
    }

    this.classList.add('onspace-player__controls--visible')
    this.detectPositionScrubberAnimation()

    if (this.canBeInvisible) {
      this.visibilityTimeout = window.setTimeout(() => this.becomeInvisible(), 4000)
    }
  }

  /// Makes the element invisible.
  becomeInvisible() {
    if (!this.canBeInvisible) { return }

    if (this.visibilityTimeout) {
      window.clearTimeout(this.visibilityTimeout)
      this.visibilityTimeout = null
    }

    this.classList.remove('onspace-player__controls--visible')
    this.detectPositionScrubberAnimation()
  }

  ////////// Structure

  /// Sets up the content which should only be displayed after playback has begun.
  ///
  /// This overrides OnspaceMediaControls.setupPlaybackContent to additionally initialise the presentation controls.
  setupPlaybackContent() {
    super.setupPlaybackContent()

    this.setupMultiScreenControls()
    this.setupPresentationControls()
  }

  ////////// Scrubbers

  /// Determines if the position scrubber should be animating.
  get canAnimatePositionScrubber() {
    return super.canAnimatePositionScrubber && (this.isVisible || this.player.presentationMode === 'pictureinpicture' || this.player.debugMode)
  }

  ////////// Multi-Screen

  /// Initialises the elements which control multi-screen.
  setupMultiScreenControls() {
    if (this.multiScreenControlsButton) { return }

    this.multiScreenControlsButton = this.barRightElement.addButton('multiscreen-controls', { icon: 'onspace/player_multiscreen', pressed: this.multiScreenControlsPressed.bind(this) })
    this.multiScreenLayoutButton = this.barRightElement.addButton('multiscreen-layout', { icon: 'onspace/player_multiscreen', pressed: this.multiScreenlayoutPressed.bind(this) })

    this.updateMultiScreenControls()
  }

  /// Updates the state of multi-screen controls.
  updateMultiScreenControls() {
    if (!this.multiScreenControlsButton) { return }

    if (this.player.canShowMultiScreenControls) {
      this.multiScreenControlsButton.style.display = ''
      this.multiScreenLayoutButton.style.display = 'none'
    } else if (this.player.canControlMultiScreenLayout) {
      this.multiScreenControlsButton.style.display = 'none'
      this.multiScreenLayoutButton.style.display = ''
      this.multiScreenLayoutButton.setIcon(this.player.multiScreen.toggleLayoutButtonIcon)
    } else {
      this.multiScreenControlsButton.style.display = 'none'
      this.multiScreenLayoutButton.style.display = 'none'
    }
  }

  ///// Events

  /// Responds to presses on the Multi Screen button.
  multiScreenControlsPressed(_event) {
    this.player.toggleMultiScreenControls()
  }

  multiScreenlayoutPressed(_event) {
    this.player.toggleMultiScreenLayout()
  }

  ////////// Presentation

  /// Initialises the elements which control presentation.
  setupPresentationControls() {
    if (this.pictureInPictureButton) { return }

    this.pictureInPictureButton = this.barRightElement.addButton('pip', { icon: 'onspace/player_pip_enter', pressed: this.pictureInPicturePressed.bind(this) })
    this.fullscreenButton = this.barRightElement.addButton('fullscreen', { icon: 'onspace/player_fullscreen_enter', pressed: this.fullscreenPressed.bind(this) })

    this.updatePresentationControls()
  }

  /// Updates the state of presentation controls.
  updatePresentationControls() {
    if (!this.fullscreenButton) { return }

    this.detectPositionScrubberAnimation()

    if (this.player.pictureInPictureActive) {
      this.pictureInPictureButton.style.display = ''
      this.pictureInPictureButton.setIcon('onspace/player_pip_exit')
    } else if (this.player.canEnterPictureInPicture) {
      this.pictureInPictureButton.style.display = ''
      this.pictureInPictureButton.setIcon('onspace/player_pip_enter')
    } else {
      this.pictureInPictureButton.style.display = 'none'
    }

    if (this.player.fullscreenActive) {
      this.fullscreenButton.style.display = ''
      this.fullscreenButton.setIcon('onspace/player_fullscreen_exit')
    } else if (this.player.canEnterFullscreen) {
      this.fullscreenButton.style.display = ''
      this.fullscreenButton.setIcon('onspace/player_fullscreen_enter')
    } else {
      this.fullscreenButton.style.display = 'none'
    }
  }

  ///// Events

  /// Responds to presses on the Picture in Picture button.
  pictureInPicturePressed(_event) {
    this.player.togglePictureInPicture()
  }

  /// Responds to presses on the Full Screen button.
  fullscreenPressed(_event) {
    this.player.toggleFullscreen()
  }

  ////////// Tabs

  ///// Events

  /// Responds to the tabs element showing its content.
  tabsExpanded(event) {
    super.tabsExpanded(event)

    this.becomeVisible()
  }

  /// Responds to the tabs element hiding its content.
  tabsCollapsed(event) {
    super.tabsCollapsed(event)

    this.becomeVisible()
  }
}

window.customElements.define('onspace-video-controls', OnspaceVideoPlayerControls)
