import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import translation from '@onpace/onspace-core/components/translations'
import OnspacePlayerBar from '@onpace/onspace-media/elements/player/bar'

export default class OnspacePlayerUpNext extends CustomHTMLElement {
  /// Runs when the element is first connected to the DOM.
  ///
  /// The following parameters are supported:
  /// [player]
  ///   The player to show the next content.
  /// [metadata]
  ///   An optional object describing the next media.
  /// [automaticPlayInterval]
  ///   The duration in seconds after which to automatically start the next media.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.player = options.player

    this.classList.add('onspace-player__upnext')

    this.contentElement = document.createElement('div')
    this.contentElement.classList.add('onspace-player__upnext__content')
    this.appendChild(this.contentElement)

    this.setupContent(options.metadata)
    this.setupNext(options.automaticPlayInterval)
  }

  /// Creates the content and structure for the element.
  setupContent(metadata) {
    const headerBarElement = new OnspacePlayerBar()
    this.contentElement.appendChild(headerBarElement)
    headerBarElement.addButton('cancel', { icon: 'onspace/icon_cross', pressed: this.cancelButtonPressed.bind(this) })

    if (metadata.artwork) {
      const artworkElement = document.createElement('img')
      artworkElement.src = metadata.artwork
      this.contentElement.appendChild(artworkElement)
    }

    const textElement = document.createElement('div')
    textElement.classList.add('onspace-player__upnext__text')

    if (metadata.title) {
      const titleElement = document.createElement('span')
      titleElement.classList.add('onspace-player__upnext__text__title')
      titleElement.innerText = metadata.title
      textElement.appendChild(titleElement)
    }

    if (metadata.subtitle) {
      const subtitleElement = document.createElement('span')
      subtitleElement.classList.add('onspace-player__upnext__text__subtitle')
      subtitleElement.innerText = metadata.subtitle
      textElement.appendChild(subtitleElement)
    }

    if (textElement.children.length > 0) {
      this.contentElement.appendChild(textElement)
    }
  }

  /// Creates the next element.
  ///
  /// When the play interval is an Integer, this will setup the element to automatically perform after the interval. It
  /// will include an animation that fills the button from left to right.
  setupNext(automaticPlayInterval) {
    this.nextButton = document.createElement('a')
    this.nextButton.classList.add('onspace-player__upnext__next')
    this.nextButton.addPressEventListeners({ onPress: this.nextButtonPressed.bind(this) })

    if (typeof automaticPlayInterval === 'number' && automaticPlayInterval > 0) {
      this.nextButtonBackground = document.createElement('div')
      this.nextButtonBackground.classList.add('onspace-player__upnext__next__background')
      this.nextButtonBackground.style.transitionDuration = `${automaticPlayInterval}s`
      this.nextButtonBackground.addEventListener('transitionend', () => {
        this.player.startNextPlayer()
      })
      this.nextButton.appendChild(this.nextButtonBackground)

      setTimeout(() => {
        this.nextButton.classList.add('onspace-player__upnext__next--automatic')
      })

      this.hoveredListener = this.hovered.bind(this)
      this.addEventListener('mousemove', this.hoveredListener)
    }

    this.nextButton.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/player_play'))

    const nextButtonText = document.createElement('span')
    nextButtonText.innerText = translation('onspace.media.player.up_next.play')
    this.nextButton.appendChild(nextButtonText)

    this.contentElement.appendChild(this.nextButton)
  }

  ////////// Events

  /// Responds to hover movements over the element.
  hovered() {
    this.removeEventListener('mousemove', this.hoveredListener)

    this.nextButton.classList.remove('onspace-player__upnext__next--automatic')
    this.nextButtonBackground.remove()
  }

  /// Responds to presses on the cancel button.
  cancelButtonPressed(_event) {
    this.player.removeUpNext()
  }

  /// Responds to presses on the next button.
  nextButtonPressed(_event) {
    this.player.startNextPlayer()
  }
}

window.customElements.define('onspace-player-upnext', OnspacePlayerUpNext)
