/// Convert a Number into a time string.
///
/// This will return a time string showing hours:minutes:seconds.
Number.prototype.toDurationString = function() {
  if (isNaN(this)) { return '00:00' }

  const { minutes, seconds } = getMinutesAndSeconds(this)
  if (minutes < 60) { return formatComponents(0, minutes, seconds) }

  const { hours, updatedMinutes } = getHoursAndUpdateMinutes(minutes)

  return formatComponents(hours, updatedMinutes, seconds)
}

/// Convert a Number into a short time string.
///
/// This will return a time string showing hours:minutes.
Number.prototype.toShortDurationString = function() {
  if (isNaN(this)) { return '00:00' }

  let { minutes, seconds } = getMinutesAndSeconds(this) // eslint-disable-line prefer-const
  if (seconds >= 30) { minutes += 1 }
  if (minutes < 60) { return formatComponents(0, minutes) }

  const { hours, updatedMinutes } = getHoursAndUpdateMinutes(minutes)

  return formatComponents(hours, updatedMinutes)
}

/// Convert a Number into a time string.
///
/// This will return a time string showing hours:minutes:seconds, but if hours is 0 will return minutes:seconds.
Number.prototype.toMediumDurationString = function() {
  if (isNaN(this)) { return '00:00' }

  const { minutes, seconds } = getMinutesAndSeconds(this)
  if (minutes < 60) { return formatComponents(minutes, seconds) }

  const { hours, updatedMinutes } = getHoursAndUpdateMinutes(minutes)

  return formatComponents(hours, updatedMinutes, seconds)
}

/// Convert a Number into a long time string.
///
/// This will return a time string showing days:hours:minutes:seconds.
Number.prototype.toLongDurationString = function() {
  if (isNaN(this)) { return '00:00' }

  const { minutes, seconds } = getMinutesAndSeconds(this)
  if (minutes < 60) { return formatComponents(0, 0, minutes, seconds) }

  const { hours, updatedMinutes } = getHoursAndUpdateMinutes(minutes)
  if (hours < 24) { return formatComponents(0, hours, updatedMinutes, seconds) }

  const { days, updatedHours } = getDaysAndUpdateHours(hours)

  return formatComponents(days, updatedHours, updatedMinutes, seconds)
}

/// Helper function to convert return human readable time.
///
/// This will pad the time with 0 if needed, and join them with a colon to reflect human readable time.
function formatComponents(...components) {
  return components
    .map(c => c.toString().padStart(2, '0'))
    .join(':')
}

/// Helper function to convert time to minutes and seconds.
///
/// Takes time as an argument - must be a Number
function getMinutesAndSeconds(time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.round(time - (minutes * 60))

  return { minutes: minutes, seconds: seconds }
}

/// Helper function to convert minutes to hours and minutes.
///
/// Takes minutes as an argument - must be a Number. Will update Minutes
function getHoursAndUpdateMinutes(minutes) {
  const hours = Math.floor(minutes / 60)
  const updatedMinutes = (hours * 60)

  return { hours: hours, updatedMinutes: updatedMinutes }
}

/// Helper function to convert hours to days and hours.
///
/// Takes hours as an argument - must be a Number. Will update Hours
function getDaysAndUpdateHours(hours) {
  const days = Math.floor(hours / 24)
  const updatedHours = (days * 24)

  return { days: days, updatedHours: updatedHours }
}
