import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// The Onspace Player Showcase is a component which displays rich information for a piece of media.
export default class OnspacePlayerShowcase extends CustomHTMLElement {
  /// Runs when the element is first connected to the DOM.
  ///
  /// The following parameters are supported:
  /// [metadata]
  ///   An object describing the next media.
  /// [actions]
  ///   An array of action buttons to include.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.classList.add('onspace-player-showcase')

    this.setupElement(options)
  }

  setupElement(options) {
    if (this.elementSetup || !options) { return }

    this.elementSetup = true

    this.setupContent(options.metadata)
    this.setupActions(options.actions)

    this.constructorOptions = null
  }

  /// Creates the content and structure for the element.
  setupContent(metadata) {
    this.contentElement = document.createElement('div')
    this.contentElement.classList.add('onspace-player-showcase__content')
    this.appendChild(this.contentElement)

    if (metadata.artwork) {
      const artworkElement = document.createElement('div')
      artworkElement.classList.add('onspace-player-showcase__image')
      artworkElement.style.backgroundImage = `url(${metadata.artwork})`
      this.contentElement.appendChild(artworkElement)
    }

    const textElement = document.createElement('div')
    textElement.classList.add('onspace-player-showcase__text')
    this.contentElement.appendChild(textElement)

    if (metadata.title) {
      const titleElement = document.createElement('span')
      titleElement.classList.add('onspace-player-showcase__text__title')
      titleElement.innerText = metadata.title
      textElement.appendChild(titleElement)
    }

    if (metadata.subtitle) {
      const subtitleElement = document.createElement('span')
      subtitleElement.classList.add('onspace-player-showcase__text__subtitle')
      subtitleElement.innerText = metadata.subtitle
      textElement.appendChild(subtitleElement)
    }
  }

  /// Creates the optional action buttons for the element.
  setupActions(actions) {
    if (!Array.isArray(actions) || actions.length === 0) { return }

    const actionsElement = document.createElement('div')
    actionsElement.classList.add('onspace-player-showcase__actions')
    this.contentElement.appendChild(actionsElement)

    actions.forEach((action) => {
      const actionButton = document.createElement('a')
      actionButton.classList.add('onspace-player-showcase__action')
      actionButton.addPressEventListeners({ onPress: action.callback })
      actionsElement.appendChild(actionButton)

      if (action.icon) {
        const iconElement = SVGElement.createOnspaceSpritemapSvg(action.icon)
        actionButton.appendChild(iconElement)
      }

      if (action.title) {
        const spanElement = document.createElement('span')
        spanElement.innerText = action.title
        actionButton.appendChild(spanElement)
      }
    })
  }
}

window.customElements.define('onspace-player-showcase', OnspacePlayerShowcase)
