import { createRoot } from 'react-dom/client'

import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import OnspaceVideoPlayer from '@onpace/onspace-media/elements/player/video'
import playerDialog from '@onpace/onspace-media/elements/player/dialog'

/// A video player wrapper to enable persistence.
class OnspaceMediaVideoWrapper extends CustomHTMLElement {
  /// Configure and initialise the player.
  ///
  /// This will initialise a new player an append it as it's only child. If a persistenceId is provided, and it matches
  /// the currently persisted player, it will insert that existing instance instead of creating a new one.
  setupPlayer({ sources, sourcesLoader, metadata, upNext, tabs, options, onMediaChanged }) {
    if (this.player) { return }

    tabs = this.parseTabData(tabs)

    if (typeof options.persistenceId === 'string' && playerDialog.playerPersisted && options.persistenceId === playerDialog.persistedPlayer.id) {
      this.player = playerDialog.persistedPlayer

      this.updateTabs(tabs)
    } else {
      this.player = new OnspaceVideoPlayer({ sources, metadata, tabs, upNext, autoplay: options.autoplay })
      if (typeof options.persistenceId === 'string') {
        this.player.id = options.persistenceId
      }
    }

    if (sourcesLoader) {
      this.player.sourcesLoader = sourcesLoader
    }

    if (typeof onMediaChanged === 'function') {
      const onMediaChangedCallback = function(_event) {
        let mediaId = null
        if (this.player.metadata && typeof this.player.metadata.id === 'string') {
          mediaId = this.player.metadata.id
        }

        onMediaChanged(mediaId)
      }
      this.player.addEventListener('onspace:media:player:media-changed', onMediaChangedCallback.bind(this))
    }

    this.appendChild(this.player)
  }

  ////////// Attributes

  /// Updates the player's metadata to the given value.
  ///
  /// This has no effect if the player has not been setup.
  updateMetadata(metadata) {
    if (!this.player) { return }

    this.player.metadata = metadata
  }

  /// Updates the player's upNext to the given value.
  ///
  /// This has no effect if the player has not been setup.
  updateUpNext(upNext) {
    if (!this.player) { return }

    this.player.setUpNext(upNext)
  }

  /// Updates the player's tabs to the given value.
  ///
  /// This has no effect if the player has not been setup.
  updateTabs(tabs) {
    if (!this.player) { return }

    tabs = this.parseTabData(tabs)
    this.player.setTabs(tabs)
  }

  /// Parses tab data converting React to HTML as necessary.
  parseTabData(tabs) {
    if (Array.isArray(tabs)) {
      tabs.forEach((tab) => {
        if (tab.type === 'custom' && tab.component) {
          const div = document.createElement('div')
          div.classList.add('onspace-player-tabs__content__custom')

          const root = createRoot(div)
          root.render(tab.component)

          tab.content = div
        }
      })

      return tabs
    } else {
      return null
    }
  }

  ////////// Methods

  /// Calls the player's +replaceMedia+ function.
  ///
  /// All arguments are forwarded. This has no effect if the player has not been setup.
  replaceMedia() {
    if (!this.player) { return }

    this.player.replaceMedia(...arguments)
  }
}

window.customElements.define('onspace-video-wrapper', OnspaceMediaVideoWrapper)
