import styles from '../../../assets/styles/base/layout'

const baseRem = parseInt(styles.baseRem.replace('px', ''))

const spacers = {}
Object.entries(styles.spacers).forEach(([size, value]) => {
  spacers[size] = value * baseRem
})

const borderWidths = {}
Object.entries(styles.borderWidths).forEach(([size, value]) => {
  borderWidths[size] = parseInt(value.replace('px', ''))
})

/// Retrieves a spacer by name.
///
/// This will locate a spacer from the registered styles. If no +size+ is provided, the default spacer will be returned.
export function getSpacer(size) {
  return spacers[size] || size || baseRem
}

/// Retrieves an icon size by name.
///
/// This is an alias to +spacer+.
const getIconSize = getSpacer
export { getIconSize }

/// Retrieves a border width by name.
///
/// this will locate a border width from the registered styles.
export function getBorderWidth(size) {
  return borderWidths[size] || null
}
