import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import { registerDialogPlayerClass } from '@onpace/onspace-media/elements/player/dialog'

/// The Onspace Iframe Player is a thin wrapper around an iframe element.
export default class OnspaceIframePlayer extends CustomHTMLElement {
  /// Sets up the iframe player element.
  ///
  /// This creates the required children and adds events where necessary.
  ///
  /// The element can be configured either from an object passed through the constructor, or as attributes on the
  /// element. The following options are supported:
  /// [src]
  ///   The source URL of the media.
  /// [sources]
  ///   An array of source objects. See OnspaceMediaPlayer.parseSources for more information.
  runFirstConnected(options = {}) {
    super.runFirstConnected()

    const sources = options.sources || this.getJsonAttribute('data-sources')
    if (sources) {
      this.src = sources[0].url
    } else {
      this.src = options.src || this.getAttribute('src')
    }

    this.iframeElement = document.createElement('iframe')
    this.iframeElement.src = this.src
    this.iframeElement.allowFullscreen = true
    this.appendChild(this.iframeElement)
  }
}

window.customElements.define('onspace-iframe', OnspaceIframePlayer)
registerDialogPlayerClass('iframe', OnspaceIframePlayer)
